import React from "react";
import { useState, useEffect } from "react";


  const TaskCard = ({
    totalTaskCount,
    lastmonthTaskCount,
    currentmonthTaskCount,
    taskDataLoading,  
  }) => {
    const [improvementPercent, setImprovementPercent] = useState(0);

    useEffect(() => {
      if (lastmonthTaskCount > 0) {
        const percentChange = (currentmonthTaskCount / lastmonthTaskCount) * 100;
        setImprovementPercent(percentChange.toFixed(2));
      }
    }, [lastmonthTaskCount, currentmonthTaskCount]);

    if (taskDataLoading) {
      return (
        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <div className="project h-100 bg-gray-200 p-3 p-lg-4 d-flex gap-3 rounded-10 shadow-sm">
            <div className="info flex-fill">
              <div className="glow-placeholder" style={{ height: "2rem", width: "50%" }}></div>
              <div className="glow-placeholder" style={{ height: "1.5rem", width: "30%", marginTop: "1rem" }}></div>
              <div className="glow-placeholder" style={{ height: "1rem", width: "70%", marginTop: "1rem" }}></div>
            </div>
            <div
              className="icon flex-shrink-0 d-flex align-items-center justify-content-center rounded-18"
              style={{
                width: "3.75rem",
                height: "3.75rem",
                backgroundColor: "rgb(254 197 61 / 20%)",
                color: "#FEC53D",
              }}
            >
              <span
                className="d-block material-symbols-outlined icon-fill"
                style={{ fontSize: "2rem" }}
              >
                stacks
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
        <div className="task h-100 bg-gray-200 p-3 p-lg-4 d-flex gap-3 rounded-10 shadow-sm">
          <div className="info flex-fill">
            <p className="fw-semibold mb-3">Tasks</p>
            <div className="d-flex align-items-end gap-1 mb-1">
              <h2 className="fw-bold lh-1 mb-0">{currentmonthTaskCount}</h2>
              <h3 className="text-gray fw-bold lh-1 mb-1">/{totalTaskCount} Tasks</h3>
            </div>
            <p className="fs-xs d-flex align-items-center">
              <span className={`d-block material-symbols-outlined icon-md me-1 ${improvementPercent >= 0 ? 'text-success' : 'text-danger'}`}>
                {improvementPercent > 0 ? 'trending_up' : 'trending_down'}
              </span>
              <span className={`d-block ${improvementPercent > 0 ? 'text-success' : 'text-danger'}`}>{Math.abs(improvementPercent)}%</span>
              <span className={`d-block text-gray ${improvementPercent > 0 ? 'text-success' : 'text-danger'}`}>
                &nbsp;{improvementPercent >= 0 ? 'Increase' : 'Decrease'} from {lastmonthTaskCount} tasks Last month 
              </span>
            </p>
          </div>
          <div
            className="icon flex-shrink-0 d-flex align-items-center justify-content-center rounded-18"
            style={{
              width: "3.75rem",
              height: "3.75rem",
              backgroundColor: "rgb(254 197 61 / 20%)",
              color: "#FEC53D",
            }}
          >
            <span
              className="d-block material-symbols-outlined icon-fill"
              style={{ fontSize: "2rem" }}
            >
              stacks
            </span>
          </div>
        </div>
      </div>
    );
  };



export default TaskCard;
