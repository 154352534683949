/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData, uploadSingleFile } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const SavePromptPopup = ({ afterPopupCLose, promptId, setPromptId }) => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [objective, setObjective] = useState("");
  const [leadowner, setLeadOwner] = useState("");
  const [engprompt, setEngprompt] = useState("");
  const [esprompt, setEsprompt] = useState("");
  const [botname, setBotName] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id


  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    slugWarning: false,
    objectiveWarnig: false,
  });

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];

    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log(response);

      if (response.status) {
        setIsUploading(false);
        setUploadedPreviewImage(response.data.path);
        setUploadedImageId(response.data._id);
      }
      resetFile();
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("uploadLogoEcosystem");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };


  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (slug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    if (objective === "") {
      setValidation((prevState) => ({ ...prevState, objectiveWarnig: true }));
      isValid = false;
    }

    return isValid;
  };

  //get details
  const getDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DETAILS_GPTPROMPT +
        `/${promptId}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTitle(response.data.title);
        setSlug(response.data.slug);
        setObjective(response.data.objective);
        setLeadOwner(response.data.leadowner);
        setEngprompt(response.data.engprompt);
        setEsprompt(response.data.esprompt);
        setBotName(response.data.botname);
        setUploadedImageId(response.data.logo._id);
        setUploadedPreviewImage(
          response.data.logo ? response.data.logo.path : ""
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create new tag
  const saveHandler = async () => {
    if (validate()) {
      setIsSaving(true);
      try {
        let promptData = {
          title,
          slug,
          objective,
          leadowner,
          botname,
          logo: uploadedImageId,
          engprompt,
          esprompt,
        };

        // console.log("Prompt------>", categoryData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (promptId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_GPTPROMPTS +
            `/${promptId}` +
            `?token=${token}`;

          response = await putData(requestUrl, promptData);
        } else {
          requestUrl =
            requestUrl + url.API_CREATE_GPTPROMPTS + `?token=${token}`;

          response = await postData(requestUrl, promptData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          // close the modal of addLabel
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#saveGptPrompt")
          ).hide();
          resetHandler();
          afterPopupCLose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  /* reset */
  const resetHandler = () => {
    setTitle("");
    setSlug("");
    setObjective("");
    setLeadOwner("");
    setEngprompt("");
    setEsprompt("");
    setValidation({
      titleWarning: false,
      slugWarning: false,
      objectiveWarnig: false,
    });
    setPromptId(null);
  };

  useEffect(() => {
    if (promptId) {
      getDetails();
    }
  }, [promptId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="saveGptPrompt"
      aria-labelledby="saveGptPrompt"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          {t("Save Prompt")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Title")}
              </label>
              <input
                type="text"
                id="labelName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter title")}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    titleWarning: false,
                  }));
                }}
              />
              {/* level warning */}
              {validation.titleWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter title")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* slug */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Slug")}
              </label>
              <input
                type="text"
                id="slug"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    slugWarning: false,
                  }));
                }}
              />
              {/* slug warning */}
              {validation.slugWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter slug")}!</span>
                  </p>
                </div>
              )}
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Chat Bot name")}
              </label>
              <input
                type="text"
                id="chatbotname"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter display name")}
                value={botname}
                onChange={(e) => {
                  setBotName(e.target.value);                  
                }}
              />
          
            </div>

            {/* ========= file upload start ========== */}
            <div className="form-group mb-4">
              {isUploading ? (
                <div className="d-flex align-items-center gap-1 mb-1">
                  <p className="fw-bold">{t("Please wait while uploading")}</p>{" "}
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                    return (
                      <div
                        key={index}
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        style={{ height: "6px", width: "6px" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <label
                  htmlFor="logo"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Logo")}
                </label>
              )}

              <input
                type="file"
                className="form-control"
                id="uploadLogoEcosystem"
                onChange={imageUploadHandler}
              />

              <div
                className="img-preview border border-2 border-gray rounded-1 overflow-hidden"
                style={{ width: "120px", height: "120px" }}
              >
                <img
                  src={
                    uploadedPreviewImage == ""
                      ? "https://placehold.co/150x150"
                      : url.SERVER_URL + uploadedPreviewImage
                  }
                  alt="user"
                  className="w-100 h-100 object-cover object-center"
                />
              </div>
            </div>
            {/* ========= file upload end ===========*/}

            {/* Lead owner */}
            <div className="form-group mb-4">
              <label
                htmlFor="leadownerId"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Lead Owner Email")}
              </label>
              <input
                type="text"
                id="leadowner"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={leadowner}
                onChange={(e) => {
                  setLeadOwner(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    slugWarning: false,
                  }));
                }}
              />
              
            </div>

            {/* <!-- ========== Start objective Section ========== --> */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Objective")}
              </label>
              <textarea
                name=""
                id=""
                rows="5"
                className="form-control fs-sm shadow-none"
                value={objective}
                onChange={(e) => {
                  setObjective(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    objectiveWarnig: false,
                  }));
                }}
              ></textarea>
              {validation.objectiveWarnig && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter Objective")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* <!-- ========== End objective Section ========== --> */}

            {/* <!-- ========== Start eng prompt Section ========== --> */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("AI Prompt")}
              </label>
              <textarea
                name=""
                id=""
                rows="5"
                className="form-control fs-sm shadow-none"
                value={engprompt}
                onChange={(e) => {
                  setEngprompt(e.target.value);
                }}
              ></textarea>
            </div>
            {/* <!-- ========== End eng prompt Section ========== --> */}

            {/* <!-- ========== Start es prompt Section ========== --> */}
            {/* <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Es Prompt
              </label>
              <textarea
                name=""
                id=""
                rows="5"
                className="form-control fs-sm shadow-none"
                value={esprompt}
                onChange={(e) => {
                  setEsprompt(e.target.value);
                }}
              ></textarea>
            </div> */}
            {/* <!-- ========== End es prompt Section ========== --> */}
          </div>
          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetHandler}
            >
              {t("Close")}
            </Link>
            <button
              onClick={saveHandler}
              type="button"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SavePromptPopup;