/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData, postData, deleteData } from "utils/Gateway";

import { assetImages } from "constants";

import CrmLeadInformationCommonHeader from "../../common/CrmLeadInformationCommonHeader";
import SaveInvoiceForm from "components/Common/SaveInvoiceForm/SaveInvoiceForm";
import SaveOpportunityHeader from "../Header/SaveOpportunityHeader";

const SaveLeadOpportunityBody = () => {
  const moduleSlug = "opportunity";
  const moduleName = "lead";

  const params = useParams();
  const history = useHistory();

  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation

  const [leadName, setleadName] = useState("");
  const [leadImage, setleadImage] = useState("");

  //function for get lead details
  const getLeadDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_LEAD_DETAILS +
        `/${params.id}?token=${token}`;

      console.log("url of lead details------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response of lead details------>", response);

      if (response.status) {
        const respondedLeadName = response.data.name
          ? `${response.data.name ?? ""} ${response.data.surname ?? ""}`
          : `${response.data.email}`;
        setleadName(respondedLeadName);

        if (response.data?.photoimage) {
          setleadImage(response.data?.photoimage?.path);
        } else {
          setleadImage("");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getLeadDetails();
    } //get lead details when id is available
  }, [params.id]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_LEAD"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <CrmLeadInformationCommonHeader
            moduleName="opportunity"
            leadName={leadName}
            leadImage={leadImage}
          />
          <div className="container-fluid px-lg-5">
            <SaveOpportunityHeader leadName={leadName} />
            <SaveInvoiceForm
              leadId={params.id}
              contactPersonId={null}
              contactId={null}
              moduleName={moduleName}
              moduleSlug={moduleSlug}
            />
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className="empty_access text-center">
        <div className="empty_pic mb-4">
          {" "}
          <img src={assetImages.emptyVector} alt="" />
        </div>
        <div className="empty_text">
          <p className="fs-lg text-gray fw-semibold mb-4">
            {t("Sorry....! You don't have privilege to see this content")}
          </p>
        </div>
      </div>
    );
  }
};

export default SaveLeadOpportunityBody;
