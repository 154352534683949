/* eslint-disable */
import React from "react";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";

const SaveOpportunityHeader = ({ contactName = "" }) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const params = useParams();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: "CRM Contact", link: "/admin/crm/contact/list" },
    {
      title: t("Opportunity"),
      link: `/admin/crm/contact/details/opportunities/${params.id}`,
    },
    {
      title: t("Save"),
    },
  ];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-6 mb-3 mb-md-0">
          {/* --- breadcrumb start --- */}
          <BreadCrumb
            breadCrumbText={breadcrumbText}
            displayName={contactName}
          />
          {/* --- breadcrumb end --- */}
        </div>
      </div>
    </div>
  );
};

export default SaveOpportunityHeader;
