export const ecosystemSlug = "LOBEES";

export const prjectManageSlug = "PROJECT_ROLE_MANAGER";

export const invoiceOwnerEmail = "admin@mpforall.com";

export const invoiceBillingAddress = {
  address: "Calle de Embajadores, 9, 28012, Madrid, España",
  supportmail: "support@elpavonteatro.es",
  phone: "(+34) 615 43 94 22",
  website: "https://club.elpavonteatro.es/",
  logourl:
    "https://club.elpavonteatro.es/wp-content/uploads/2024/08/TeatroPavon-RGB-N-1.png",
};

// export const joditEditorConfig = {
//   readonly: false,
//   placeholder: "",
//   minHeight: 400,
//   uploader: {
//     insertImageAsBase64URI: true,
//   },
//   defaultActionOnPaste: "insert_as_html",
//   defaultLineHeight: 1.5,
//   enter: "div",
//   statusbar: false,
//   sizeLG: 900,
//   sizeMD: 700,
//   sizeSM: 400,
//   toolbarAdaptive: false,
//   disablePlugins: [
//     "video",
//     "about",
//     "classSpan",
//     "file",
//     "speechRecognize",
//     "copyformat",
//     "spellcheck",
//   ],
// };

export const joditEditorConfig = (editorHeight = null) => {
  return {
    readonly: false,
    placeholder: "",
    minHeight: editorHeight || 400,
    uploader: {
      insertImageAsBase64URI: false,
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };
};

export const ckEditorConfig = (placeholderText = "") => {
  return {
    toolbar: [
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "undo",
      "redo",
    ],
    placeholder: placeholderText,
    shouldNotGroupWhenFull: false,
  };
};

export const gptPromtSlug = "INVOICE_DOC";
