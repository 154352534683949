import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import * as url from "helper/UrlHelper";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ckEditorConfig } from "Config/Config";
import downloadFileHandler from "helper/DownloadFile";

const EventDetailsForm = ({
  isSaving = false,
  seterrorMessage,
  validation,
  setValidation,
  eventTypeList = [],
  eventTypeValue,
  eventTypeSelectionHandler,
  eventTitle,
  seteventTitle,
  eventSlug,
  setEventSlug,
  isRoot,
  setIsRoot,
  parentEventOptions = [],
  parentValue,
  parentSelectionHandler,
  parnetInputHandler,
  openImageFileInput,
  imageFileInputRef,
  imageUploadHandler,
  isUploading,
  closeEventImageFileHandler,
  uploadedPreviewImage,
  eventSummary,
  seteventSummary,
  eventDetails,
  seteventDetails,
  isVirtual,
  setisVirtual,
  virtualUrl,
  setvirtualUrl,
  zipcode,
  setzipcode,
  getLocationFromZip,
  cityName,
  setcityName,
  stateName,
  setstateName,
  country,
  setcountry,
  location,
  setlocation,
  companyOptionsToShow,
  companyValue,
  companySelectionHandler,
  setcompanyInput,
  startDate,
  setstartDate,
  startTime,
  setstartTime,
  endDate,
  setendDate,
  endTime,
  setendTime,
  eventBudget,
  seteventBudget,
  eventWebsite,
  seteventWebsite,
  categoryList,
  categoryValue,
  categorySelectionHandler,
  searchingList,
  searchingValue,
  searchingSelectionHandler,
  interestList,
  interestValue,
  interestSelectionHandler,
  openDocFileInput,
  docFileInputRef,
  docFileUploadHandler,
  docFileUploading,
  loadingCircle = [],
  uploadedDocFiles = [],
  closeDocFileHandler,
  autoAccept,
  setautoAccept,
  isPrivate,
  setisPrivate,
  errorMessage = "",
  saveUpdateEventHandler,
}) => {
  const { t } = useTranslation(); //for translation

  return (
    <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
      <form onSubmit={(e) => e.preventDefault()}>
        {/* ----- event type and title section start ------ */}
        <div className="form-group mb-3 mb-sm-4">
          <div className="row">
            {/* ----- event type section start ------ */}
            <div className="col-lg-3 mb-3 mb-sm-4 mb-lg-0">
              <label htmlFor="type" className="d-block fs-sm fw-semibold mb-2">
                {t("Type")}
              </label>
              <Select
                options={eventTypeList}
                value={eventTypeValue}
                onChange={(val) => {
                  eventTypeSelectionHandler(val);
                }}
              />
            </div>
            {/* ----- event type section end ------ */}

            {/* ----- event title section start ------ */}
            <div className="col-lg-9">
              <label htmlFor="title" className="d-block fs-sm fw-semibold mb-2">
                {t("Title")}
              </label>
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Name")}
                value={eventTitle}
                onChange={(e) => {
                  seteventTitle(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    titleWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              {validation.titleWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter title")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ----- event title section start ------ */}
          </div>
        </div>
        {/* ----- event type and title section end ------ */}

        {/* slug */}
        <div className="form-group mb-3 mb-sm-4">
          <label htmlFor="title" className="d-block fs-sm fw-semibold mb-2">
            {t("Slug")}
          </label>
          <input
            type="text"
            className="form-control fs-sm shadow-none"
            placeholder={t("Name")}
            value={eventSlug}
            onChange={(e) => {
              setEventSlug(e.target.value);
              setValidation((prevState) => ({
                ...prevState,
                titleWarning: false,
              }));
              seterrorMessage("");
            }}
          />
          {validation.slugWarning && (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please enter slug")}!</span>
              </p>
            </div>
          )}
        </div>

        {/* ----- isroot section start ------ */}
        <div className="form-group mb-3 mb-sm-4">
          <div className="row">
            {/* ----- isroot checkbox section start ---- */}
            <div className="col-lg-2 mb-3 mb-sm-4 mb-lg-0">
              <label
                htmlFor="isroot"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Is Root")}
              </label>

              <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
                <input
                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  type="checkbox"
                  checked={isRoot}
                  onChange={(e) => {
                    setIsRoot(e.target.checked);
                    setValidation((prevState) => ({
                      ...prevState,
                      parentWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
              </div>
            </div>
            {/* ---- isroot checkbox section end ---- */}

            {/* ---- select parent section start ---- */}
            {isRoot ? null : (
              <div className="col-lg-10">
                <label
                  htmlFor="virtualMeetURL"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Select Parent")}
                </label>
                <Select
                  isClearable
                  options={parentEventOptions}
                  placeholder={t("Search by event title")}
                  value={parentValue}
                  onChange={(val) => {
                    parentSelectionHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      parentWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                  onInputChange={(val) => {
                    parnetInputHandler(val);
                  }}
                />
                {validation.parentWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t("Please select parent or click Is Root")} !
                      </span>
                    </p>
                  </div>
                )}
              </div>
            )}
            {/* ---- select parent url section end ---- */}
          </div>
        </div>
        {/* ----- isroot section end ------ */}

        {/* ------ uploaded image section start ------ */}
        <div className="form-group">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10 mb-3">
                  <label htmlFor="uploadDp" className="text-nowrap me-3">
                    {t("Upload Event Banner")}
                  </label>
                  <p className="form-control text-gray-600 border-0 rounded-0 shadow-none">
                    JPG, PNG, JPEG
                  </p>
                  <Link
                    to="#"
                    className="upload-file"
                    data-target="uploadResume"
                    onClick={openImageFileInput}
                  >
                    <span className="d-block material-symbols-outlined icon-lg">
                      upload
                    </span>
                  </Link>
                  <input
                    type="file"
                    data-id="uploadResume"
                    id="uploadedEventImageFile"
                    className="d-none"
                    accept="image/*"
                    ref={imageFileInputRef}
                    onChange={imageUploadHandler}
                  />
                </div>

                <ul className="preview-uploaded-files d-flex flex-wrap gap-2">
                  {isUploading ? (
                    <li>
                      <div className="d-flex align-items-center gap-1 ms-2 mb-1">
                        <p className="fw-bold">
                          {t("Please wait while uploading")}
                        </p>{" "}
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                          return (
                            <div
                              key={index}
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                              style={{
                                height: "5px",
                                width: "5px",
                              }}
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </li>
                  ) : (
                    <li className="file border border-gray rounded-8 overflow-hidden position-relative">
                      <Link to="#" onClick={closeEventImageFileHandler}>
                        <span className="d-block material-symbols-outlined text-white icon-lg position-absolute top-0 end-0 mt-2 me-1">
                          delete
                        </span>
                      </Link>
                      <img
                        src={
                          uploadedPreviewImage === ""
                            ? "https://placehold.co/150x150"
                            : url.SERVER_URL + uploadedPreviewImage
                        }
                        alt="Uploaded"
                        className="w-100 h-100 object-fit-cover object-center"
                      />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* ------ uploaded image section end ------ */}

        {/* ----- event summary section start ------ */}
        <div className="form-group mt-3 mb-3 mb-sm-4">
          <label htmlFor="summary" className="d-block fs-sm fw-semibold mb-2">
            {t("Summary")}
          </label>
          <textarea
            name="summary"
            id="summary"
            cols="30"
            rows="3"
            className="form-control fs-sm shadow-none"
            placeholder={t("Event Summary")}
            value={eventSummary}
            onChange={(e) => seteventSummary(e.target.value)}
          ></textarea>
        </div>
        {/* ----- event summary section end ------ */}

        {/* ----- event details section start ------ */}
        <div className="form-group mb-3 mb-sm-4">
          <label htmlFor="details" className="d-block fs-sm fw-semibold mb-2">
            {t("Details")}
          </label>

          <CKEditor
            className="form-control fs-sm shadow-none"
            editor={ClassicEditor}
            config={ckEditorConfig("Event details")}
            data={eventDetails ? eventDetails : ""}
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "200px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            onChange={(event, editor) => {
              let editorContent = editor.getData();
              seteventDetails(editorContent);
            }}
          />
        </div>
        {/* ----- event details section end ------ */}

        {/* ----- virtual section start ------ */}
        <div className="form-group mb-3 mb-sm-4">
          <div className="row">
            {/* ---- virtual checkbox section start ---- */}
            <div className="col-lg-2 mb-3 mb-sm-4 mb-lg-0">
              <label
                htmlFor="virtual"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Virtual")}
              </label>

              <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
                <input
                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  type="checkbox"
                  checked={isVirtual}
                  onChange={(e) => setisVirtual(e.target.checked)}
                />
              </div>
            </div>
            {/* ---- virtual checkbox section end ---- */}

            {/* ---- virtual meet url section start ---- */}
            {isVirtual && (
              <div className="col-lg-10">
                <label
                  htmlFor="virtualMeetURL"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Virtual Meet URL")}
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter URL")}
                  value={virtualUrl}
                  onChange={(e) => setvirtualUrl(e.target.value)}
                />
              </div>
            )}
            {/* ---- virtual meet url section end ---- */}
          </div>
        </div>
        {/* ----- virtual section end ------ */}

        {/* ----- zipcode,city,state,country section start ------ */}
        <div className="form-group mb-3">
          <div className="row">
            {/* ----- zipcode section start ------ */}
            <div className="col-lg-4 mb-3 mb-lg-0">
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control border-0 shadow-none"
                    placeholder={t("Zipcode")}
                    value={zipcode}
                    onChange={(e) => {
                      setzipcode(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        zipcodeWarning: false,
                      }));
                      seterrorMessage("");
                    }}
                  />
                  {validation.zipcodeWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter zipcode")}!</span>
                      </p>
                    </div>
                  )}
                </div>
                <div className="col">
                  <button
                    className="btn btn-primary"
                    onClick={getLocationFromZip}
                  >
                    {t("Fetch Location by zipcode")}
                  </button>
                </div>
              </div>
            </div>
            {/* ----- zipcode section end ------ */}

            {/* ----- city,state,country section start ------ */}
            <div className="col-lg-8">
              <div className="row">
                {/* ----- city section start ------ */}
                <div className="col-lg-4 mb-3 mb-lg-0">
                  <input
                    type="text"
                    className="form-control border-0 shadow-none"
                    placeholder={t("City")}
                    value={cityName}
                    onChange={(e) => {
                      setcityName(e.target.value);
                    }}
                  />
                </div>
                {/* ----- city section end ------ */}

                {/* ----- state section start ------ */}
                <div className="col-lg-4 mb-3 mb-lg-0">
                  <input
                    type="text"
                    className="form-control border-0 shadow-none"
                    placeholder={t("State")}
                    value={stateName}
                    onChange={(e) => {
                      setstateName(e.target.value);
                    }}
                  />
                </div>
                {/* ----- state section end ------ */}

                {/* ----- country section start ------ */}
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control border-0 shadow-none"
                    placeholder={t("Country")}
                    value={country}
                    onChange={(e) => {
                      setcountry(e.target.value);
                    }}
                  />
                </div>
                {/* ----- country section end ------ */}
              </div>
            </div>
            {/* ----- city,state,country section end ------ */}
          </div>
        </div>
        {/* ----- zipcode,city,state,country section end ------ */}

        {/* ----- address section start ------ */}
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label
                htmlFor="address"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Address")}
              </label>
              <div className="field-container d-flex align-items-center pe-3 body-bg rounded-10">
                <input
                  type="text"
                  className="form-control border-0 shadow-none"
                  placeholder={t("Location")}
                  value={location}
                  onChange={(e) => {
                    setlocation(e.target.value);
                  }}
                />
                <span className="d-block material-symbols-outlined icon-lg text-gray">
                  my_location
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group mb-3">
              <label
                htmlFor="address"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Company")}
              </label>

              <Select
                placeholder={t("Type Company name")}
                isClearable
                options={companyOptionsToShow}
                value={companyValue}
                onChange={(val) => {
                  companySelectionHandler(val);
                }}
                onInputChange={(val) => {
                  setcompanyInput(val);
                }}
              />
            </div>
          </div>
        </div>
        {/* ----- address section end ------ */}

        {/* ----- start date/time and end date/time section start ----- */}
        <div className="form-group mb-3 mb-sm-4">
          <div className="row">
            <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
              <label htmlFor="start" className="d-block fs-sm fw-semibold mb-2">
                {t("Start")}
              </label>
              <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                <input
                  type="date"
                  className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                  placeholder={t("Date")}
                  value={startDate}
                  onChange={(e) => {
                    setstartDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      startDateWarning: false,
                      dateDiffWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
                <input
                  type="time"
                  className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                  placeholder={t("Time")}
                  value={startTime}
                  onChange={(e) => {
                    setstartTime(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      startTimeWarning: false,
                      timeDiffWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
              </div>
              <div className="d-flex gap-2">
                {validation.startDateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter start date")}!</span>
                    </p>
                  </div>
                )}
                {validation.startTimeWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter start time")}!</span>
                    </p>
                  </div>
                )}
                {validation.dateDiffWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t("End date should not be less then start date")}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <label htmlFor="end" className="d-block fs-sm fw-semibold mb-2">
                {t("End")}
              </label>
              <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                <input
                  type="date"
                  className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                  placeholder={t("Date")}
                  value={endDate}
                  onChange={(e) => {
                    setendDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      endDateWarning: false,
                      dateDiffWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
                <input
                  type="time"
                  className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                  placeholder={t("Time")}
                  value={endTime}
                  onChange={(e) => {
                    setendTime(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      endTimeWarning: false,
                      timeDiffWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
              </div>
              <div className="d-flex gap-2">
                {validation.endDateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter end date")}!</span>
                    </p>
                  </div>
                )}
                {validation.endTimeWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter end time")}!</span>
                    </p>
                  </div>
                )}
                {validation.timeDiffWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t("End time should not be less then start time")}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* ----- start date/time and end date/time section end ----- */}

        {/* ----- budget section start ----- */}
        <div className="form-group mb-3 mb-sm-4">
          <label htmlFor="budget" className="d-block fs-sm fw-semibold mb-2">
            {t("Budget")}
          </label>
          <input
            type="number"
            className="form-control fs-sm shadow-none"
            placeholder={t("Enter Budget")}
            value={eventBudget}
            onChange={(e) => seteventBudget(e.target.value)}
          />
        </div>
        {/* ----- budget section end ----- */}

        {/* ----- website section start ----- */}
        <div className="form-group mb-3 mb-sm-4">
          <label htmlFor="budget" className="d-block fs-sm fw-semibold mb-2">
            {t("Website")}
          </label>
          <input
            type="text"
            className="form-control fs-sm shadow-none"
            placeholder={t("Enter url")}
            value={eventWebsite}
            onChange={(e) => seteventWebsite(e.target.value)}
          />
        </div>
        {/* ----- website section end ----- */}

        {/* ----- category section start ----- */}
        <div className="form-group mb-3 mb-sm-4">
          <label htmlFor="category" className="d-block fs-sm fw-semibold mb-2">
            {t("Category")}
          </label>
          <Select
            isMulti
            options={categoryList}
            value={categoryValue}
            onChange={(val) => {
              categorySelectionHandler(val);
              setValidation((prevState) => ({
                ...prevState,
                categoryWarning: false,
              }));
              seterrorMessage("");
            }}
          />
          {validation.categoryWarning && (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please select category")}!</span>
              </p>
            </div>
          )}
        </div>
        {/* ----- category section end ----- */}

        {/* ----- searching section start ----- */}
        <div className="form-group mb-3 mb-sm-4">
          <label htmlFor="searching" className="d-block fs-sm fw-semibold mb-2">
            {t("Searching")}
          </label>
          <Select
            isMulti
            options={searchingList}
            value={searchingValue}
            onChange={(val) => searchingSelectionHandler(val)}
          />
        </div>
        {/* ----- searching section end ----- */}

        {/* ----- interest section start ----- */}
        <div className="form-group mb-3 mb-sm-4">
          <label htmlFor="interest" className="d-block fs-sm fw-semibold mb-2">
            {t("Interest")}
          </label>
          <Select
            isMulti
            options={interestList}
            value={interestValue}
            onChange={(val) => interestSelectionHandler(val)}
          />
        </div>
        {/* ----- interest section end ----- */}

        {/* ------ upload documnet section start ------- */}
        <div className="form-group mb-3 mb-sm-4">
          <label htmlFor="documents" className="d-block fs-sm fw-semibold mb-2">
            {t("Upload Documents")}
          </label>

          <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
            <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
              <Link
                to="#"
                data-target="upload"
                className="upload-file text-center"
                onClick={openDocFileInput}
              >
                <span className="d-block material-symbols-outlined">
                  upload
                </span>
                <span className="d-block fw-semibold">{t("Upload")}</span>
              </Link>
              <span className="fs-xs">Max 20mb doc/img/mp4</span>
              <input
                id="uploadedEventDoc"
                ref={docFileInputRef}
                onChange={docFileUploadHandler}
                type="file"
                data-id="upload"
                className="d-none"
                multiple
              />
            </li>
          </ul>

          {docFileUploading ? (
            <div className="d-flex align-items-center mt-3">
              <p className="mb-0 me-1">{t("Uploading Files")}</p>
              {loadingCircle.map((circle) => {
                return (
                  <div
                    key={circle}
                    className="spinner-grow mx-1"
                    style={{ height: "6px", width: "6px" }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                );
              })}
            </div>
          ) : (
            <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
              {uploadedDocFiles.map((file, index) => {
                return (
                  <li
                    className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                    key={index}
                  >
                    <Link to="#" onClick={() => downloadFileHandler(file)}>
                      <span className="d-block material-symbols-outlined">
                        download
                      </span>
                    </Link>
                    <p className="fw-medium">{file.name}</p>
                    <Link to="#" onClick={() => closeDocFileHandler(index)}>
                      <span className="d-block material-symbols-outlined">
                        close
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        {/* ------ upload documnet section end ------- */}

        {/* ----- auto accept section start ----- */}
        <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-3">
          <input
            className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
            type="checkbox"
            checked={autoAccept}
            onChange={(e) => setautoAccept(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="autoaccept">
            {t("Auto accept joining invitations")}
          </label>
        </div>
        {/* ----- auto accept section end ----- */}

        {/* ----- is private section start ----- */}
        <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
          <input
            className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
            type="checkbox"
            checked={isPrivate}
            onChange={(e) => setisPrivate(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="private">
            {t("Mark as private event")}
          </label>
        </div>
        {/* ----- is private section end ----- */}

        {errorMessage === "" ? null : (
          <div className="error-message mb-2">
            <p className="d-flex align-items-center gap-1 text-danger">
              <span className="material-symbols-outlined">warning</span>
              <span>{errorMessage}!</span>
            </p>
          </div>
        )}

        {/* ----- cancel and save button start ----- */}
        <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
          <Link to="/admin/events/list" className="btn btn-outline-primary">
            {t("Cancel")}
          </Link>
          <button
            type="button"
            className="btn btn-primary"
            onClick={saveUpdateEventHandler}
            disabled={isSaving ? true : false}
            style={{
              cursor: isSaving ? "not-allowed" : "pointer",
            }}
          >
            {t("Save Now")}
            {isSaving && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
        {/* ----- cancel and save button end ----- */}
      </form>
    </div>
  );
};

export default EventDetailsForm;
