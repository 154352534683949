/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import languageOptions from "data/Prod/LanguageOptions.json";

import {
  getData,
  uploadSingleFile,
  uploadMultipleFile,
  postData,
  putData,
} from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";
import { ecosystemSlug, ckEditorConfig } from "Config/Config";
import { appUserHeaderLinks } from "helper/AppUserHelper/AppUserHelper";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AfterSaveUserModal from "components/Common/Modal/AfterSaveUserModal";
import { getPublicIP } from "helper/AuthHelper/AuthHelper";

const AppUserProfileInfoBody = () => {
  const params = useParams();
  const history = useHistory();

  const { t } = useTranslation(); //for translation

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App User"), link: "/admin/appuser/list" },
    { title: t("Profile Info") },
  ];

  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = appUserHeaderLinks(params.id, t);
  const loadingCircle = [1, 2, 3, 4, 5, 6];

  const profileImageRef = useRef(null);
  const companyImageRef = useRef(null);
  const uploadFileInputRef = useRef(null);

  /* ========= use states =========== */
  const [ecosystemList, setEcosystemList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const [profilePicId, setProfilePicId] = useState(null);
  const [companyImagePath, setcompanyImagePath] = useState("");
  const [companyImageId, setcompanyImageId] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [companyImageUploading, setcompanyImageUploading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [position, setPosition] = useState("");
  const [companyValue, setCompanyValue] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [bioDetails, setBioDetails] = useState("");
  const [address, setAddress] = useState("");
  const [rank, setRank] = useState(1);
  const [website, setWebsite] = useState("");
  const [rate, setRate] = useState("");
  const [skillValue, setSkillValue] = useState(null);
  const [skills, setSkills] = useState([]);
  const [interestValue, setInterestValue] = useState(null);
  const [interest, setInterest] = useState([]);
  const [languageValue, setLanguageValue] = useState(null);
  const [language, setLanguage] = useState("");
  const [roleValue, setRoleValue] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [userHobbiesValue, setUserHobbiesValue] = useState(null);
  const [userSelectedHobbies, setUserSelectedHobbies] = useState([]);
  const [mailSignature, setMailSignature] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [ecosystemValue, setEcosystemValue] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const [savedUserId, setsavedUserId] = useState(null);

  //** function for get all modules
  const getAllEcosystems = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_ECOSYSTEM + `?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setEcosystemList(
          response.data.map((item) => ({
            label: item.name,
            value: item._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all categories
  const getAllCategories = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=skills&ecosystemslug=${ecosystemSlug}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setSkillList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all user roles
  const getAllRoles = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_ROLES + `?token=${token}`;

      //console.log('all roles', requestUrl);

      const response = await getData(requestUrl);

      if (response.status) {
        setRoleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(
          response.data.map((item) => ({
            label: item.name,
            value: item.name,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();

    if (zipcode !== "") {
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;

        const response = await getData(url);

        if (response.status === "OK") {
          const results = response.results;

          if (results.length > 0) {
            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));
              if (item.types[0] === "country") {
                setcountry(item?.long_name);
              }
              if (item.types[0] === "locality") {
                setcityName(item?.long_name);
              }
              if (item.types[0] === "administrative_area_level_1") {
                setstateName(item?.long_name);
              }
            });
            // setMapCoordinate(
            //   `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`
            // );
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  //function for open cover image file input
  const profileImageOpen = () => {
    profileImageRef.current.click();
  };

  //function for upload cover image
  const profileImageUploadHandler = async (e) => {
    const file = e.target.files[0];

    setIsImageUploading(true);

    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log("upload response-------->", response);

      if (response.status) {
        setIsImageUploading(false);
        setProfilePic(response.data.path);
        setProfilePicId(response.data._id);
        resetProfileImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetProfileImageFile = () => {
    const file = document.getElementById("uploadprofileimage");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for open company image file
  const openCompanyImageFileInput = () => {
    companyImageRef.current.click();
  };

  //function for upload compnay image
  const companyImageUploadHandler = async (e) => {
    const file = e.target.files[0];
    setcompanyImageUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;
      const response = await uploadSingleFile(requestUrl, file);
      setcompanyImageUploading(false);
      console.log("upload response-------->", response);
      if (response.status) {
        setcompanyImagePath(response.data.path);
        setcompanyImageId(response.data._id);
        resetCompanyImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for close image file
  const closeCompanyImageHandler = () => {
    setcompanyImagePath("");
    setcompanyImageId(null);
    resetCompanyImageFile();
  };

  // function for clear file value
  const resetCompanyImageFile = () => {
    const file = document.getElementById("uploadedCompanyImage");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for select skills
  const skillSelectionHandler = (val) => {
    setSkillValue(val);
    const valArr = val.map((item) => item.value);
    setSkills(valArr);
  };

  //function for select interest
  const interestSelectionHandler = (val) => {
    setInterestValue(val);
    const valArr = val.map((item) => item.value);
    setInterest(valArr);
  };

  //function for select language
  const languageSelectionHandler = (val) => {
    setLanguageValue(val);
    setLanguage(val.value);
  };

  //** function for select hobbies */
  const hobbiesSelectionHandler = (val) => {
    setUserHobbiesValue(val);
    const hobbiesArr = val.map((item) => item.value);
    setUserSelectedHobbies(hobbiesArr);
  };

  //function for select role
  const roleSelectionHandler = (val) => {
    setRoleValue(val);
    setRoleId(val.value);
  };

  //function for open image imput
  const openUploadFileInput = () => {
    uploadFileInputRef.current.click();
  };

  //function for image upload
  const docFileUploadHandler = async (e) => {
    try {
      setIsFileUploading(true);
      const docUploadedFiles = e.target.files;
      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;
      const response = await uploadMultipleFile(requestUrl, docUploadedFiles);
      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );
        console.log(uploadedFileValues);
        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/
        setIsFileUploading(false);
        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));
        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedFileIds.length == 0) {
          setUploadedFileIds(fileIds);
        } else {
          let fileArr = uploadedFileIds.concat(fileIds);
          setUploadedFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetDocFileInput();
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...uploadedFileIds];
    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);
    setUploadedFiles(closedFile);
    setUploadedFileIds(removedFileId);
    resetDocFileInput();
  };

  //function for reset video inputs
  const resetDocFileInput = () => {
    const file = document.getElementById("docfileinput");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      setCompanyValue(val);
      setCompanyName(val.value);
    } else {
      setCompanyValue(null);
      setCompanyName("");
    }
  };

  //function for get user details
  const getUserDetails = async () => {
    const userPublicIp = await getPublicIP();

    // console.log("userInfo", userInfo);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}` +
        `?token=${token}&userip=${userPublicIp}`;

      console.log("requestUrl", requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;
        setProfilePic(data.userimage || ""); // Ensure default value if undefined
        setProfilePicId(data.userimageid || null); // Ensure default value if undefined
        setcompanyImagePath(response.data.companyimagepath || "");
        setcompanyImageId(response.data.companyimageid || null);
        setName(data.name || "");
        setSurName(data.surname || "");
        setEmail(data.email || "");
        setTelephone(data.phone || "");
        setPosition(data.position || "");
        const companyResponse = data.company
          ? {
              label: data.company,
              value: data.company,
            }
          : null;
        companySelectionHandler(companyResponse);
        setBioDetails(data.about || "");
        setAddress(data.address || "");
        setRank(data.rank || 1);
        setWebsite(data.website || "");
        setRate(data.costperhour || "");
        const skillData = data.userskills || [];
        skillSelectionHandler(skillData);
        const interestData = data.userinterests || [];
        interestSelectionHandler(interestData);

        //** assign hobbies */
        const hobbiesArr = data.hobbies || [];
        const hobbiesData = hobbiesArr.map((item) => ({
          label: item,
          value: item,
        }));
        hobbiesSelectionHandler(hobbiesData);

        for (let language of languageOptions) {
          if (language.value == data.language) {
            languageSelectionHandler(language);
          }
        }
        if (data.userrole) {
          roleSelectionHandler(data.userrole);
        }
        setstateName(data.state || "");
        setcityName(data.city || "");
        setcountry(data.country || "");
        setzipcode(data.zipcode || "");
        setMailSignature(data?.mailsignature ?? "");
        const uploadedFilesData = data.uploads || [];
        setUploadedFiles(
          uploadedFilesData.map(({ _id, name, path, ...other }) => ({
            _id,
            name,
            path: url.SERVER_URL + path,
          }))
        );
        const fileIds = uploadedFilesData.map(({ _id, ...other }) => ({
          _id,
        }));
        setUploadedFileIds(fileIds);
        setEcosystemValue(response.data.ecosystemvalue);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save/update user
  const saveUserHandler = async () => {
    const userPublicIp = await getPublicIP();

    if (email != "") {
      try {
        setIsSaving(true);
        let userData = {
          ecosystemid: ecosystemValue ? ecosystemValue.value : null,
          photoimage: profilePicId,
          name,
          surname: surName,
          email,
          position,
          company: companyName,
          companylogo: companyImageId || null,
          language,
          role: roleId,
          phone: telephone,
          address,
          about: bioDetails,
          city: cityName,
          state: stateName,
          country,
          zipcode,
          adminstatus: "1",
          costperhour: rate,
          uploads: uploadedFileIds,
          rank,
          skills,
          interests: interest,
          website,
          mailsignature: mailSignature,
          hobbies: userSelectedHobbies,
        };

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (params.id) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_APP_USER +
            `/${params.id}` +
            `?token=${token}&userip=${userPublicIp}`;

          response = await putData(requestUrl, userData);
        } else {
          requestUrl =
            requestUrl +
            url.API_ADD_NEW_USER +
            `?token=${token}&ecosystemslug=${ecosystemSlug}`;

          response = await postData(requestUrl, userData);
        }

        setIsSaving(false);
        console.log(response);

        if (response.status) {
          setsavedUserId(response.data._id);
          resetHandler();
          const bootstrapModal = new bootstrap.Modal(
            document.getElementById("afterSaveUserModal")
          );
          bootstrapModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //function for reset
  const resetHandler = () => {
    setcompanyInput("");
    setCompanyList([]);
    setProfilePic("");
    setProfilePicId(null);
    setName("");
    setSurName("");
    setEmail("");
    setTelephone("");
    setPosition("");
    setCompanyValue(null);
    setCompanyName("");
    setBioDetails("");
    setAddress("");
    setRank(1);
    setWebsite("");
    setRate("");
    setSkillValue(null);
    setSkills([]);
    setInterestValue(null);
    setInterest([]);
    setLanguageValue(null);
    setLanguage("");
    setRoleValue(null);
    setRoleId(null);
    setstateName("");
    setcityName("");
    setcountry("");
    setzipcode("");
    setUserHobbiesValue(null);
    setUserSelectedHobbies([]);
    setMailSignature("");
    setUploadedFiles([]);
    setUploadedFileIds([]);
    setEcosystemValue(null);
  };

  //after modal close
  const afterModalClose = (pageName) => {
    if (pageName == "details") {
      history.push(`/admin/appuser/profile/${savedUserId}`);
    } else {
      resetHandler();
      history.push("/admin/appuser/list");
    }
  };

  useEffect(() => {
    if (params.id) {
      getUserDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getAllCategories();
    getAllRoles();
    getAllEcosystems();
  }, []);

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);

  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  /**
   ** check logged in user is admin or super admin or access this module.
   */
  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_USER") ||
    userInfo._id.toString() === params.id.toString()
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- expert details common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Profile info")}
            />
          )}
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ---- bread crumb ------- */}
            <BreadCrumb
              breadCrumbText={breadcrumbText}
              bottom={true}
              displayName={`${name} ${surName}`}
            />
            {/* --- add/update section start----- */}
            <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
              <form onSubmit={(e) => e.preventDefault()}>
                {/* --- user's image ---- */}
                <div className="form-group mb-3 mb-sm-4">
                  <div
                    className="avatar-container position-relative rounded-circle overflow-hidden"
                    style={{ width: "120px", height: "120px" }}
                  >
                    <img
                      src={
                        profilePic == ""
                          ? assetImages.defaultUser
                          : url.SERVER_URL + profilePic
                      }
                      className="w-100 h-100 object-fit-cover object-center"
                      alt="Expert"
                    />
                    <Link
                      to="#"
                      onClick={profileImageOpen}
                      id="uploadAvatar"
                      className="block text-center text-white px-3 py-2 position-absolute start-0 end-0 bottom-0"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                      <span className="d-block material-symbols-outlined icon-lg">
                        photo_camera
                      </span>
                    </Link>
                    <input
                      onChange={profileImageUploadHandler}
                      ref={profileImageRef}
                      type="file"
                      id="uploadprofileimage"
                      className="d-none"
                      accept="image/*"
                    />
                  </div>
                </div>
                {/* ---- name / surname ------ */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="name"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Name")}*
                      </label>
                      <input
                        type="text"
                        id={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter Name")}
                        value={name}
                        required
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="surname"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Surname")}*
                      </label>
                      <input
                        type="text"
                        id="surname"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter Surname")}
                        value={surName}
                        onChange={(e) => setSurName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* ----- email / telephone / position ------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-4 mb-3 mb-sm-3 mb-lg-0">
                      <label
                        htmlFor="email"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Email")}*
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter Email")}
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4 mb-3 mb-sm-3 mb-lg-0">
                      <label
                        htmlFor="telephone"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Telephone")}
                      </label>
                      <input
                        type="tel"
                        id="telephone"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter Telephone")}
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label
                        htmlFor="position"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Position")}
                      </label>
                      <input
                        type="text"
                        id="position"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter Position")}
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* company and logo start */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    {/* company name */}
                    <div className="col-lg-6">
                      <label
                        htmlFor="position"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Company Name")}
                      </label>
                      {/* <input
                      type="text"
                      id="position"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter Company Name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    /> */}
                      <CreatableSelect
                        placeholder={t("Type Company name")}
                        isClearable
                        options={companyOptionsToShow}
                        value={companyValue}
                        onChange={(val) => {
                          companySelectionHandler(val);
                        }}
                        onInputChange={(val) => {
                          setcompanyInput(val);
                        }}
                      />
                    </div>
                    {/* company logo */}
                    <div className="col-lg-6">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10 mb-3">
                        <label htmlFor="uploadDp" className="text-nowrap me-3">
                          {t("Upload Company Logo")}
                        </label>
                        <p className="form-control text-gray-600 border-0 rounded-0 shadow-none">
                          JPG,PNG
                        </p>
                        <Link
                          to="#"
                          className="upload-file"
                          data-target="uploadCompanyLogo"
                          onClick={openCompanyImageFileInput}
                        >
                          <span className="d-block material-symbols-outlined icon-lg">
                            upload
                          </span>
                        </Link>
                        <input
                          type="file"
                          data-id="uploadCompanyImg"
                          id="uploadedCompanyImage"
                          className="d-none"
                          ref={companyImageRef}
                          onChange={companyImageUploadHandler}
                        />
                      </div>
                      <ul className="preview-uploaded-files d-flex flex-wrap gap-2">
                        {companyImageUploading ? (
                          <li>
                            <div className="d-flex align-items-center gap-1 ms-2 mb-1">
                              <p className="fw-bold">
                                {t("Please wait while uploading")}
                              </p>{" "}
                              {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    style={{
                                      height: "5px",
                                      width: "5px",
                                    }}
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </li>
                        ) : (
                          <li className="file border border-gray rounded-8 overflow-hidden position-relative">
                            <Link to="#" onClick={closeCompanyImageHandler}>
                              <span className="d-block material-symbols-outlined text-white icon-lg position-absolute top-0 end-0 mt-2 me-1">
                                delete
                              </span>
                            </Link>
                            <img
                              src={
                                companyImagePath == ""
                                  ? "https://placehold.co/150x150"
                                  : url.SERVER_URL + companyImagePath
                              }
                              alt="Uploaded"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* ecosystem */}
                {userInfo.role && userInfo.role.slug == "SUPER_ADMIN" ? (
                  <div className="form-group mb-3 mb-sm-4">
                    <div className="row">
                      <div className="col-lg-6 mb-3 mb-sm-6 mb-lg-0">
                        <label
                          htmlFor="bioDetails"
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          {t("Ecosystem")}
                        </label>
                        <Select
                          options={ecosystemList}
                          value={ecosystemValue}
                          onChange={(val) => setEcosystemValue(val)}
                        />
                      </div>
                      {/* ----- select role ----- */}
                      <div className="col-lg-6 mb-3 mb-sm-6 mb-lg-0">
                        <label
                          htmlFor="Role"
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          {t("Select Role")}
                        </label>
                        <Select
                          placeholder={t("Select Role")}
                          options={roleList}
                          value={roleValue}
                          onChange={(val) => {
                            roleSelectionHandler(val);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* ------- bio details -------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="bioDetails"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("About")}
                  </label>
                  <CKEditor
                    className="form-control fs-sm shadow-none"
                    editor={ClassicEditor}
                    config={ckEditorConfig(t("Type details here..."))}
                    data={bioDetails ? bioDetails : ""}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      let editorContent = editor.getData();
                      setBioDetails(editorContent);
                    }}
                  />
                </div>
                {/* ------ zipcode,location,city,country ----- */}
                <div className="form-group mb-3">
                  <div className="row">
                    {/* ----- zipcode section start ------ */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="row">
                        <div className="col">
                          <input
                            type="text"
                            className="form-control border-0 shadow-none"
                            placeholder={t("Zipcode")}
                            value={zipcode}
                            onChange={(e) => {
                              setzipcode(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col">
                          <button
                            className="btn btn-primary"
                            onClick={getLocationFromZip}
                          >
                            {t("Fetch Location by zipcode")}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* ----- zipcode section end ------ */}
                    {/* ----- city,state,country section start ------ */}
                    <div className="col-lg-8">
                      <div className="row">
                        {/* ----- city section start ------ */}
                        <div className="col-lg-4 mb-3 mb-lg-0">
                          <input
                            type="text"
                            className="form-control border-0 shadow-none"
                            placeholder={t("City")}
                            value={cityName}
                            onChange={(e) => {
                              setcityName(e.target.value);
                            }}
                          />
                        </div>
                        {/* ----- city section end ------ */}
                        {/* ----- state section start ------ */}
                        <div className="col-lg-4 mb-3 mb-lg-0">
                          <input
                            type="text"
                            className="form-control border-0 shadow-none"
                            placeholder={t("State")}
                            value={stateName}
                            onChange={(e) => {
                              setstateName(e.target.value);
                            }}
                          />
                        </div>
                        {/* ----- state section end ------ */}
                        {/* ----- country section start ------ */}
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control border-0 shadow-none"
                            placeholder={t("Country")}
                            value={country}
                            onChange={(e) => {
                              setcountry(e.target.value);
                            }}
                          />
                        </div>
                        {/* ----- country section end ------ */}
                      </div>
                    </div>
                    {/* ----- city,state,country section end ------ */}
                  </div>
                </div>
                {/* ---------Address and rank --------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="address"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Address")}
                      </label>
                      <input
                        type="text"
                        className="form-control border-0 shadow-none"
                        placeholder={t("Location")}
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="rank"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Rank")}
                      </label>
                      <input
                        type="number"
                        className="form-control border-0 shadow-none"
                        placeholder={t("Rank")}
                        value={rank}
                        onChange={(e) => {
                          setRank(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* -------- website and rate ------------ */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="website"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Website")}
                      </label>
                      <input
                        type="text"
                        id="website"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Paste link")}
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="ratePerHour"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        <span>{t("Rate Per Hour")}</span>
                        <span className="ms-1 fs-xs fw-normal text-gray">
                          {t("(If working as freelancer)")}
                        </span>
                      </label>
                      <input
                        type="text"
                        id="ratePerHour"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter rate")}
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* ------- skills --------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="skills"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Basic Skills")}
                  </label>
                  <Select
                    isMulti
                    placeholder={t("Select Skills")}
                    options={skillList}
                    value={skillValue}
                    onChange={(val) => {
                      skillSelectionHandler(val);
                    }}
                  />
                </div>

                {/* --------- interest --------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="interest"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Select Interests")}
                  </label>
                  <Select
                    isMulti
                    placeholder={t("Select Interest")}
                    options={skillList}
                    value={interestValue}
                    onChange={(val) => {
                      interestSelectionHandler(val);
                    }}
                  />
                </div>

                {/* -------- languages --------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="language"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Select Language")}
                  </label>
                  <Select
                    placeholder={t("Select Language")}
                    options={languageOptions}
                    value={languageValue}
                    onChange={(val) => {
                      languageSelectionHandler(val);
                    }}
                  />
                </div>

                {/* <!-- ========== Start select hobbies Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="language"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Type Hobbies")}
                  </label>
                  <CreatableSelect
                    isMulti
                    placeholder={t("Type Hobbies")}
                    value={userHobbiesValue}
                    onChange={(val) => {
                      hobbiesSelectionHandler(val);
                    }}
                  />
                </div>
                {/* <!-- ========== End select hobbies Section ========== --> */}

                {/* mail signature */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="mailsignature"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Mail Signature")}
                  </label>
                  <CKEditor
                    className="form-control fs-sm shadow-none"
                    editor={ClassicEditor}
                    config={ckEditorConfig(t("Type mail signature here..."))}
                    data={mailSignature ? mailSignature : ""}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      let editorContent = editor.getData();
                      setMailSignature(editorContent);
                    }}
                  />
                </div>
                {/* ------ attachment section start ------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="attachments"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Documents")}
                  </label>
                  <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                    <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
                      <Link
                        to="#"
                        data-target="upload"
                        className="upload-file text-center"
                        onClick={openUploadFileInput}
                      >
                        <span className="d-block material-symbols-outlined">
                          upload
                        </span>
                        <span className="d-block fw-semibold">
                          {t("Upload")}
                        </span>
                      </Link>
                      <span className="fs-xs">Max 20mb doc/img/mp4</span>
                      <input
                        id="docfileinput"
                        ref={uploadFileInputRef}
                        onChange={docFileUploadHandler}
                        type="file"
                        data-id="upload"
                        className="d-none"
                        multiple
                      />
                    </li>
                  </ul>
                  {isFileUploading ? (
                    <div className="d-flex align-items-center mt-3">
                      <p className="mb-0 me-1">Uploading Files</p>
                      {loadingCircle.map((circle) => {
                        return (
                          <div
                            key={circle}
                            className="spinner-grow mx-1"
                            style={{ height: "6px", width: "6px" }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                      {uploadedFiles.map((file, index) => {
                        return (
                          <li
                            className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                            key={index}
                          >
                            <Link
                              to="#"
                              onClick={() => downloadFileHandler(file)}
                            >
                              <span className="d-block material-symbols-outlined">
                                download
                              </span>
                            </Link>
                            <p className="fw-medium">{file.name}</p>
                            <Link
                              to="#"
                              onClick={() => closeDocFileHandler(index)}
                            >
                              <span className="d-block material-symbols-outlined">
                                close
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
                {/* ------ attachment section end ------- */}
                {/* ------- save button ---------- */}
                <div className="action">
                  <button
                    onClick={saveUserHandler}
                    type="button"
                    className="btn btn-primary"
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Save")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
            {/* --- add/update section end----- */}
          </div>
        </section>

        <AfterSaveUserModal
          userId={params.id}
          afterSaveModalClose={afterModalClose}
          moduleName={"Profile"}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default AppUserProfileInfoBody;
