/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";
/*import url and gateway methods */

import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import RecievedHeader from "../Header/RecievedHeader";
import ConversationFilterPopup from "../Popup/ConversationFilterPopup";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import ConversationNewMailPopup from "components/Common/Popup/ConversationNewMailPopup";
import ConversationMailPopup from "components/Common/Popup/ConversationMailPopup";

import { assetImages } from "constants";
import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";

import { useTranslation } from "react-i18next";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

const RecievedListBody = () => {
  const token = localStorage.getItem("token");
  const moduleAccess = localStorage.getItem("moduleaccess");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const [tagList, setTagList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mailList, setMailList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedMailIds, setSelectedMailIds] = useState([]);

  const [selectedMailId, setSelectedMailId] = useState(null);

  const [filterDateRange, setFilterDateRange] = useState("");
  const [filterSubject, setfilterSubject] = useState("");
  const [filterSender, setfilterSender] = useState(null);
  const [filterTemplates, setfilterTemplates] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [filterHasAttachment, setFilterHasAttachment] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: "CRM", link: "/admin/crm/conversation/list" },
    { title: t("Conversation") },
  ];

  // common header object
  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  // get all mail templates
  const getAllMailTemplates = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_MAIL_TEMPLATES + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTemplateList(
          response.data.map((template) => ({
            label: template.name,
            value: template._id,
          }))
        );
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  // get all received mails -----------------------------------------
  // with search params ------------------------------------
  const getAllRecievedMails = async () => {
    setRowSelection({});
    setSelectedMailIds([]);

    try {
      setIsLoading(true);

      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_RECIEVED_MAIL}?token=${token}`;

      if (filterDateRange != "") {
        requestUrl = requestUrl + `&maildate=${filterDateRange}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&maillabels=${filterTags}`;
      }

      if (filterTemplates.length > 0) {
        requestUrl = requestUrl + `&mailtemplates=${filterTemplates}`;
      }

      if (filterSender) {
        requestUrl = requestUrl + `&mailsender=${filterSender}`;
      }

      if (filterSubject != "") {
        requestUrl = requestUrl + `&mailsubject=${filterSubject}`;
      }

      if (filterHasAttachment == true) {
        requestUrl = requestUrl + `&attachment=1`;
      }

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setMailList(response.data);
        resetFilterData();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  // change mail status
  const changeStatusHandler = async () => {
    if (selectedMailIds.length > 0) {
      let responseArr = [];

      for (let mailId of selectedMailIds) {
        try {
          let mailData = {
            receiverstatus: "0",
          };
          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_MAIL +
            `/${mailId}` +
            `?token=${token}`;
          const response = await putData(requestUrl, mailData);

          if (response.status) {
            setMessageType("success");
            responseArr.push(true);
          } else {
            setMessageType("error");
          }

          setAlertMessage(response.message);
        } catch (error) {
          setMessageType("error");
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllRecievedMails();
        }, 2500);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select mail for delete");
      setShowAlert(true);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange("");
    setfilterSubject("");
    setfilterTemplates([]);
    setFilterTags([]);
    setfilterSender(null);
    setFilterHasAttachment(false);
  };

  useEffect(() => {
    if (
      filterDateRange != "" ||
      filterSubject != "" ||
      filterTags.length > 0 ||
      filterTemplates.length > 0 ||
      filterHasAttachment == true ||
      filterSender
    ) {
      getAllRecievedMails();
    }
  }, [
    filterSubject,
    filterTemplates,
    filterDateRange,
    filterTags,
    filterSender,
    filterHasAttachment,
  ]);

  useEffect(() => {
    getAllRecievedMails();
    getAllTags();
    getAllMailTemplates();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasEmail"
            aria-controls="offcanvasEmail"
            onClick={() => {
              setSelectedMailId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "subject",
        header: t("Mail Subject"),
      },
      {
        accessorKey: "sendermail",
        header: t("Sent From"),
        Cell: ({ row }) => (
          <div className="sent-from border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "22px", height: "22px" }}
              >
                <img
                  src={
                    row.original.senderimage == ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.senderimage
                  }
                  alt="Alan Benjamin"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p>{row.original.sendername}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "maildate",
        header: t("Date"),
      },
      {
        accessorKey: "tagstring",
        header: t("Tags"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectedMailIds(selectedIdsArray);
    } else {
      setSelectedMailIds([]);
    }
  }, [rowSelection]);

  /**
   * Closes the alert by updating the state variables.
   *
   * @return {void} No return value.
   */
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") &&
      moduleAccess.includes("MOD_CONVERSATION"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper crm-conversation-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Conversation")}
          />
          <div className="container-fluid px-lg-5">
            <RecievedHeader
              breadCrumbText={breadcrumbText}
              reloadList={getAllRecievedMails}
            />
            <div className="row">
              <div className="col-lg-12 mb-4 mb-lg-0">
                <ul
                  className="nav nav-tabs d-inline-flex p-1 body-bg flex-nowrap fs-md lh-1 fw-semibold border-0 text-nowrap rounded-10 overflow-auto mb-2"
                  id="mailTab"
                >
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/admin/crm/conversation/recieved"
                      className="text-black d-flex align-items-center justify-content-center gap-2 rounded-10 active"
                    >
                      <span className="d-block material-symbols-outlined icon-lg">
                        archive
                      </span>
                      <span className="d-block">{t("Received")}</span>
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/admin/crm/conversation/sent"
                      className="text-black d-flex align-items-center justify-content-center gap-2 rounded-10"
                    >
                      <span className="d-block material-symbols-outlined icon-lg">
                        unarchive
                      </span>
                      <span className="d-block">{t("Sent")}</span>
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      to="/admin/crm/conversation/sentreport"
                      className="text-black d-flex align-items-center justify-content-center gap-2 rounded-10"
                    >
                      <span className="d-block material-symbols-outlined icon-lg">
                        Assessment
                      </span>
                      <span className="d-block">{t("Report")}</span>
                    </Link>
                  </li>
                </ul>
                {isLoading ? (
                  <div className="placeholder-glow d-flex flex-column gap-4">
                    <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                    <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                    <span className="placeholder placeholder-lg bg-secondary col-4"></span>
                  </div>
                ) : (
                  <div className="table-wrapper">
                    <MaterialReactTable
                      columns={columns} // map columns to be displayed with api data,
                      data={mailList} // data from api to be displayed
                      enableGrouping
                      enableRowSelection // enable showing checkbox
                      getRowId={(row) => row._id} // map which value to select with row checkbox
                      onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                      state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                      defaultColumn={{
                        minSize: 20,
                        maxSize: 200,
                        size: 50, //make columns wider by default
                      }}
                      muiTableContainerProps={{
                        sx: {
                          maxHeight: "60vh",
                        },
                      }}
                      enableStickyHeader
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* ==== modals and popup area ==== */}

        {/* --- filter popup --- */}
        <ConversationFilterPopup
          tagList={tagList}
          templateList={templateList}
          setFilterDateRange={setFilterDateRange}
          setfilterSubject={setfilterSubject}
          setfilterTemplates={setfilterTemplates}
          setFilterTags={setFilterTags}
          setfilterSender={setfilterSender}
          setFilterHasAttachment={setFilterHasAttachment}
        />

        {/* --- new mail popup --- */}
        <ConversationNewMailPopup
          reloadList={() => {
            setTimeout(() => {
              getAllRecievedMails();
            }, 2500);
          }}
          contactPersonEmail={""}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ---  mail popup --- */}
        <ConversationMailPopup
          selectedMailId={selectedMailId}
          resetMailId={() => {
            setSelectedMailId(null);
          }}
        />

        <AddLabelModal
          moduleName="conversation"
          selectedIds={selectedMailIds}
          afterTagModalClose={() => {
            setTimeout(() => {
              getAllRecievedMails();
            }, 2500);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedMailIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t("conversation")}
        />
        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default RecievedListBody;
