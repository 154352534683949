import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const AboutAccordion = ({
  validation = {},
  setValidation,
  firstName = "",
  setFirstName,
  lastName = "",
  setLastName,
  emailAddress = "",
  setEmailAddress,
  website = "",
  setWebsite,
  phoneNumber = "",
  setPhoneNumber,
  dateOfBirth = "",
  setDateOfBirth,
  companyOptionsToShow = [],
  companyValue = null,
  companySelectionHandler,
  setcompanyInput,
  position = "",
  setposition,
  linkdin = "",
  setLinkdin,
  mentorOptionsToShow = [],
  mentorValue = null,
  mentorSelectionHandler,
  mentorInputHandler,
  followersOptionsToShow = [],
  followrsValue = null,
  followersSelectionHandler,
  onFollowersInputChange,
  openImageFileInput,
  imageFileInputRef,
  imageUploadHandler,
  isUploading = false,
  closeLeadImageFileHandler,
  uploadedPreviewImage = "",
  interest = "",
  setInterest,
  leadHobbiesValue = null,
  hobbiesSelectionHandler,
  SubscribeOptions = [],
  subscribeMailValue = null,
  subscribeSelectionHandler,
  subscribeAcceptOn = "",
  setSubscribeAcceptOn,
  subscribeDeninedOn = "",
  setSubscribeDeninedOn,
}) => {
  const { t } = useTranslation();

  return (
    <div className="accordion-item bg-transparent border-0">
      {/* ------ toggle accordion button start ------ */}
      <h3 className="accordion-header">
        <button
          className="accordion-button bg-transparent p-0 shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseOne"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseOne"
        >
          {t("About")}
        </button>
      </h3>
      {/* ------ toggle accordion button end ------ */}
      {/* ------ about section start ------ */}
      <div
        id="panelsStayOpen-collapseOne"
        className="accordion-collapse collapse show"
      >
        <div className="accordion-body p-0 pt-4">
          {/* ------ firstname ,lastname section start ------ */}
          <div className="form-group mb-3">
            <div className="row">
              {/* ------ firstname section start ------ */}
              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="website" className="text-nowrap me-3">
                    {t("First Name")}
                  </label>{" "}
                  <input
                    type="text"
                    id="fName"
                    className="form-control border-0 shadow-none"
                    placeholder={t("First Name")}
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        firstNameWarning: false,
                      }));
                    }}
                  />
                </div>

                {/* name warning */}
                {validation.firstNameWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter a name")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ------ firstname section end ------ */}
              {/* ------ lastname section start ------ */}
              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="website" className="text-nowrap me-3">
                    {t("Last Name")}
                  </label>
                  <input
                    type="text"
                    id="lName"
                    className="form-control border-0 shadow-none"
                    placeholder={t("Last Name")}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              {/* ------ lastname section end ------ */}
            </div>
          </div>
          {/* ------ firstname ,lastname section end ------ */}
          {/* ------ email ,phone ,dob,company and position section start ------ */}
          <div className="form-group mb-3">
            <div className="row">
              {/* ------ email section start ------ */}
              <div className="col-lg-6 mb-3 mb-lg-0">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="website" className="text-nowrap me-3">
                    {t("Email")}
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control border-0 shadow-none"
                    placeholder={t("Email Address")}
                    value={emailAddress}
                    onChange={(e) => {
                      setEmailAddress(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        emailAddressWarning: false,
                        notValidMail: false,
                      }));
                    }}
                  />
                </div>
                {/* email warning */}
                {validation.emailAddressWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter email")}!</span>
                    </p>
                  </div>
                )}
                {/* email warning */}
                {validation.notValidMail && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter a vaild email")}!</span>
                    </p>
                  </div>
                )}
              </div>{" "}
              {/* ------ email section end ------ */}
              {/* <!-- ========== Start website Section ========== --> */}
              <div className="col-lg-6 mb-3 mb-lg-0">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="website" className="text-nowrap me-3">
                    {t("Website")}
                  </label>
                  <input
                    type="text"
                    className="form-control border-0 shadow-none"
                    placeholder={t("Enter website")}
                    value={website}
                    onChange={(e) => {
                      setWebsite(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* <!-- ========== End website Section ========== --> */}
            </div>
          </div>

          {/* ---- phone and dob section start ----- */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="phone" className="text-nowrap me-3">
                    {t("Phone")}
                  </label>

                  <input
                    type="text"
                    className="form-control border-0 shadow-none"
                    placeholder={t("Phone")}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="dob" className="text-nowrap me-3">
                    {t("DOB")}
                  </label>
                  <input
                    type="date"
                    className="form-control border-0 border-end rounded-0 shadow-none"
                    placeholder="DD"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* ------ email ,phone ,dob,company and position section end ------ */}
          {/* ------ company and postion section start ------ */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <div className="field-container d-flex align-items-center ps-3 py-1 body-bg rounded-10">
                  <label htmlFor="dob" className="text-nowrap me-3">
                    {t("Company")}
                  </label>
                  <CreatableSelect
                    className="w-100"
                    placeholder={t("Type Company name")}
                    isClearable
                    options={companyOptionsToShow}
                    value={companyValue}
                    onChange={(val) => {
                      companySelectionHandler(val);
                    }}
                    onInputChange={(val) => {
                      setcompanyInput(val);
                    }}
                  />
                </div>

                {/* company warning */}
                {validation.companyWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter a company")}!</span>
                    </p>
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="position">{t("Position")}</label>
                  <input
                    type="text"
                    id="position"
                    className="form-control border-0 shadow-none"
                    placeholder={t("Enter position")}
                    value={position}
                    onChange={(e) => setposition(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* ------ company and postion section end ------ */}

          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
              <label htmlFor="linkdin" className="text-nowrap me-3">
                {t("Linkdin")}
              </label>
              <input
                type="text"
                className="form-control border-0 rounded-0 shadow-none"
                placeholder={t("Enter url")}
                value={linkdin}
                onChange={(e) => {
                  setLinkdin(e.target.value);
                }}
              />
            </div>
          </div>

          {/* <!-- ========== Start mentor Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center ps-3 py-1 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Mentor (contact who provided this lead)")}
              </label>
              <Select
                isClearable
                className="w-100"
                placeholder={t("Search by name, email")}
                options={mentorOptionsToShow}
                value={mentorValue}
                onChange={(val) => {
                  mentorSelectionHandler(val);
                }}
                onInputChange={(val) => {
                  mentorInputHandler(val);
                }}
              />
            </div>
          </div>
          {/* <!-- ========== End mentor Section ========== --> */}

          {/* <!-- ========== Start follower Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center ps-3 py-1 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Followers")}
              </label>
              <Select
                isMulti
                className="w-100"
                placeholder={t("Search by name,email")}
                options={followersOptionsToShow}
                value={followrsValue}
                onChange={(val) => {
                  followersSelectionHandler(val);
                }}
                onInputChange={(val) => {
                  onFollowersInputChange(val);
                }}
              />
            </div>
          </div>

          {/* <!-- ========== End follower Section ========== --> */}

          {/* ------ uploaded image section start ------ */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center px-3 body-bg rounded-10 mb-3">
              <label htmlFor="uploadDp" className="text-nowrap me-3">
                {t("Upload DP")}
              </label>
              <p className="form-control text-gray-600 border-0 rounded-0 shadow-none">
                DOCX, PDF, DOC
              </p>
              <Link
                to="#"
                className="upload-file"
                data-target="uploadResume"
                onClick={openImageFileInput}
              >
                <span className="d-block material-symbols-outlined icon-lg">
                  upload
                </span>
              </Link>
              <input
                type="file"
                data-id="uploadResume"
                id="uploadedImage"
                className="d-none"
                ref={imageFileInputRef}
                onChange={imageUploadHandler}
                accept="image/*"
              />
            </div>
            <ul className="preview-uploaded-files d-flex flex-wrap gap-2">
              {isUploading ? (
                <li>
                  <div className="d-flex align-items-center gap-1 ms-2 mb-1">
                    <p className="fw-bold">
                      {t("Please wait while uploading")}
                    </p>{" "}
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                      return (
                        <div
                          key={index}
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          style={{
                            height: "5px",
                            width: "5px",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      );
                    })}
                  </div>
                </li>
              ) : (
                <li className="file border border-gray rounded-8 overflow-hidden position-relative">
                  <Link to="#" onClick={closeLeadImageFileHandler}>
                    <span className="d-block material-symbols-outlined text-white icon-lg position-absolute top-0 end-0 mt-2 me-1">
                      delete
                    </span>
                  </Link>
                  <img
                    src={
                      uploadedPreviewImage === ""
                        ? "https://placehold.co/150x150"
                        : uploadedPreviewImage
                    }
                    alt="Uploaded"
                    className="w-100 h-100 object-fit-cover object-center"
                  />
                </li>
              )}
            </ul>
          </div>
          {/* ------ uploaded image section end ------ */}

          {/* ------ interest section start ------ */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Professional Interest")}
              </label>
              <input
                type="text"
                className="form-control border-0 rounded-0 shadow-none"
                placeholder={t("Enter Interest")}
                value={interest}
                onChange={(e) => {
                  setInterest(e.target.value);
                }}
              />
            </div>
          </div>
          {/* ------ interest section end ------ */}

          {/* <!-- ========== Start select hobbies Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center ps-3 py-1 body-bg rounded-10">
              <label htmlFor="Subscribe" className="text-nowrap me-3">
                {t("Hobbies")}
              </label>
              <CreatableSelect
                isMulti
                className="w-100"
                placeholder={t("Type Hobbies")}
                value={leadHobbiesValue}
                onChange={(val) => {
                  hobbiesSelectionHandler(val);
                }}
              />
            </div>
          </div>
          {/* <!-- ========== End select hobbies Section ========== --> */}

          {/* <!-- ========== Start subscribe Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center ps-3 py-1 body-bg rounded-10">
              <label htmlFor="Subscribe" className="text-nowrap me-3">
                {t("Subscribe to commercial emails")}
              </label>
              <Select
                className="w-100"
                options={SubscribeOptions}
                value={subscribeMailValue}
                onChange={(val) => subscribeSelectionHandler(val)}
              />
            </div>
          </div>

          <div className="form-group mb-3">
            <div className="row">
              {subscribeAcceptOn === "" ? null : (
                <div className="col-lg-6">
                  <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                    <label htmlFor="dob">{t("Subscribe accept on")}</label>
                    <input
                      type="date"
                      className="form-control border-0 border-end rounded-0 shadow-none"
                      value={subscribeAcceptOn}
                      onChange={(e) => setSubscribeAcceptOn(e.target.value)}
                    />
                  </div>
                </div>
              )}

              {subscribeDeninedOn === "" ? null : (
                <div className="col-lg-6">
                  <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                    <label htmlFor="dob">{t("Subscribe denied on")}</label>
                    <input
                      type="date"
                      className="form-control border-0 border-end rounded-0 shadow-none"
                      value={subscribeDeninedOn}
                      onChange={(e) => setSubscribeDeninedOn(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <!-- ========== End subscribe Section ========== --> */}
        </div>
      </div>
      {/* ------ about section end ------ */}
    </div>
  );
};

export default AboutAccordion;
