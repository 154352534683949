/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
  deleteData,
} from "utils/Gateway";

import { assetImages } from "constants";
import PersonalInfoModal from "components/Common/Modal/CvComponentModals/PersonalInfoModal";
import FetchFromDocModal from "components/Common/Modal/CvComponentModals/FetchFromDocModal";
import RolesModal from "components/Common/Modal/CvComponentModals/RolesModal";
import CarrierHighlightsModal from "components/Common/Modal/CvComponentModals/CarrierHighlightsModal";
import SummaryModal from "components/Common/Modal/CvComponentModals/SummaryModal";
import DetailsModal from "components/Common/Modal/CvComponentModals/DetailsModal";
import SkillPointModal from "components/Common/Modal/CvComponentModals/SkillPointModal";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";
import ExperienceModal from "components/Common/Modal/CvComponentModals/ExperienceModal";
import ProjectReferencesModal from "components/Common/Modal/CvComponentModals/ProjectReferencesModal";
import EducationModal from "components/Common/Modal/CvComponentModals/EducationModal";
import TechnicalCertificateModal from "components/Common/Modal/CvComponentModals/TechnicalCertificateModal";
import HobbiesModal from "components/Common/Modal/CvComponentModals/HobbiesModal";
import PortfolioModal from "components/Common/Modal/CvComponentModals/PortfolioModal";
import OtherSkillsModal from "components/Common/Modal/CvComponentModals/OtherSkillsModal";
import SalaryModal from "components/Common/Modal/CvComponentModals/SalaryModal";
import LanguageModal from "components/Common/Modal/CvComponentModals/LanguageModal";
import ApplySuccessModal from "components/Common/Modal/CvComponentModals/ApplySuccessModal";

const SaveLeadCvForm = ({ showChallenge = false, previousUrl = "" }) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const imageFileInputRef = useRef(null);

  const [challengeList, setchallengeList] = useState([]);

  const [cvId, setcvId] = useState(null);

  const [cvAdded, setCvAdded] = useState(false); // check if CV data is added by the user or not

  const [challenegeValue, setchallenegeValue] = useState(null);
  const [challengeId, setchallengeId] = useState(null);

  const [companyName, setcompanyName] = useState("");
  const [cvTitle, setCvTitle] = useState("");
  const [userImagePath, setuserImagePath] = useState("");
  const [userImageId, setuserImageId] = useState(null);
  const [userPosition, setuserPosition] = useState("Applicant");
  const [userEmail, setuserEmail] = useState("applicant@lobees.com");
  const [userPhone, setuserPhone] = useState("");
  const [userName, setuserName] = useState("Name");
  const [userSurname, setuserSurname] = useState("Surname");
  const [userAddress, setUserAddress] = useState("");
  const [userZipcode, setUserZipcode] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userExperienceInYears, setUserExperienceInYears] = useState("");

  const [jobTitle, setJobTitle] = useState("");

  const [userSummary, setuserSummary] = useState("");
  const [userDetails, setuserDetails] = useState("");
  const [fetchingProfileData, setFetchingProfileData] = useState(false);
  const [uploadedDocUrl, setUploadedDocUrl] = useState(""); //uploaed doc requiremtnts
  const [fetchingUploadedData, setFetchingUploadedData] = useState(false);

  /* ----- skill point block requirement start ------*/
  const [skillPointData, setSkillPointData] = useState({
    skill: null,
    skillvalue: null,
    skillname: "",
    points: "",
    experienceyear: "",
  });
  const [skillPointListBlock, setSkillPointListBlock] = useState([]);
  const [skillPointIndex, setSkillPointIndex] = useState(null);

  /* ----- skill point block requirement end ------*/

  /* ----- experience block requirement start ------*/
  const [experienceData, setExperienceData] = useState({
    companyid: null,
    company: "",
    companylogo: "",
    position: "",
    start: "",
    end: "",
    ctc: "",
    responsibilities: "",
  });

  const [experienceListBlock, setExperienceListBlock] = useState([]);
  const [experienceIndex, setExperienceIndex] = useState(null);
  /* ----- experience block requirement end ------*/

  /* ----- project reference block requirement start ------*/
  const [projectData, setProjectData] = useState({
    companyid: null,
    company: "",
    technologies: "",
    position: "",
    start: "",
    end: "",
    responsibilities: "",
  });

  const [projectListBlock, setProjectListBlock] = useState([]);
  const [projectIndex, setProjectIndex] = useState(null);
  /* ----- project reference block requirement end ------*/

  /* ----- education block requirement start ------*/
  const [educationData, setEducationData] = useState({
    degree: "",
    year: "",
    grade: "",
    institute: "",
  });

  const [educationListBlock, setEducationListBlock] = useState([]);
  const [educationIndex, setEducationIndex] = useState(null);
  /* ----- education block requirement end ------*/

  /* ----- Certificates block requirement start ------*/
  const [technicalExperienceData, settechnicalExperienceData] = useState({
    name: "",
    companyvalue: null,
    companyname: "",
    start: "",
    end: "",
    description: "",
    grade: "",
  });
  const [technicalExpBlock, settechnicalExpBlock] = useState([]);
  const [technicalExpIndex, settechnicalExpIndex] = useState(null);
  /* ----- Certificates block requirement end ------*/

  const [userLanguages, setUserLanguages] = useState([]);

  const [userCurrency, setuserCurrency] = useState("");
  const [userCurrCtc, setuserCurrCtc] = useState("");
  const [userExpctCtc, setuserExpctCtc] = useState("");
  const [userWorkingHours, setuserWorkingHours] = useState("");
  const [userStartTime, setuserStartTime] = useState("");
  const [userEndTime, setuserEndTime] = useState("");
  const [userCountry, setuserCountry] = useState("");

  const [userOptionalSkills, setuserOptionalSkills] = useState([]);

  const [userPortfolioLinks, setuserPortfolioLinks] = useState([]);

  const [userHobbies, setuserHobbies] = useState([]);
  const [userRoles, setuserRoles] = useState([]);
  const [userOtherSkills, setuserOtherSkills] = useState([]);
  const [userCarrierHighlights, setUserCareerHighlights] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const [isJobApplied, setIsJobApplied] = useState(false);

  // ai params for file handeling and chat ---------------------
  const [assistantId, setAssistantId] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [docContent, setDocContent] = useState("");

  const [validationError, setValidationError] = useState({
    cvTitleError: false,
    cvEmailError: false,
    cvNameError: false,
    cvSurnameError: false,
    cvPositonError: false,
    challengeError: false,
  });

  //validation function
  const validationHandler = () => {
    let isValid = true;

    if (cvTitle === "") {
      setValidationError((prev) => ({
        ...prev,
        cvTitleError: true,
      }));

      isValid = false;
    }

    if (userEmail === "applicant@lobees.com") {
      setValidationError((prev) => ({
        ...prev,
        cvEmailError: true,
      }));

      isValid = false;
    }

    // if (userName === "Name") {
    //   setValidationError((prev) => ({
    //     ...prev,
    //     cvNameError: true,
    //   }));

    //   isValid = false;
    // }

    // if (userSurname === "Surname") {
    //   setValidationError((prev) => ({
    //     ...prev,
    //     cvSurnameError: true,
    //   }));

    //   isValid = false;
    // }

    // if (userPosition === "Applicant") {
    //   setValidationError((prev) => ({
    //     ...prev,
    //     cvPositonError: true,
    //   }));

    //   isValid = false;
    // }

    if (showChallenge && challenegeValue == null) {
      setValidationError((prev) => ({
        ...prev,
        challengeError: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //function for get challenge list
  const getChallengeList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_LIST_FOR_OPTIONS +
        "?token=" +
        token;

      let response = await getData(requestUrl);

      console.log(response);

      setchallengeList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  //function for select challenge
  const challengeSelectionHandler = (val) => {
    if (val) {
      setchallenegeValue(val);
      setchallengeId(val.value);
      setJobTitle(val.label);
      setValidationError((prev) => ({
        ...prev,
        challengeError: false,
      }));
      setErrorMessage("");
    } else {
      setchallenegeValue(null);
      setchallengeId(null);
      setJobTitle("");
    }
  };

  //function for open image imput
  const openImageFileInput = () => {
    imageFileInputRef.current.click();
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];

    console.log(file);

    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log("upload response-------->", response);

      if (response.status) {
        setuserImagePath(response.data.path);
        setuserImageId(response.data._id);
        resetProfileImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetProfileImageFile = () => {
    const file = document.getElementById("uploadedImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //update skillpoint block
  const updateSkillPointBlock = (skillpoint, index) => {
    setSkillPointIndex(index);
    setSkillPointData(skillpoint);
  };

  //function for delete skillpoint block
  const onDeleteSkillPointBlock = (index) => {
    const filteredSkillPointBlock = [...skillPointListBlock];
    filteredSkillPointBlock.splice(index, 1);
    setSkillPointListBlock(filteredSkillPointBlock);
  };

  //function for view skillpoint blocks
  const getSkillPointsBlock = (skillPoints) => {
    const skillPintArr =
      skillPoints &&
      skillPoints.map((skillPoint) => {
        return {
          _id: skillPoint?._id,
          skill: skillPoint?.skill,
          skillvalue: skillPoint?.skillvalue,
          skillname: skillPoint?.skillname,
          points: skillPoint?.points,
          experienceyear: skillPoint?.experienceyear,
        };
      });
    setSkillPointListBlock(skillPintArr);
  };

  //*function for edit experience block
  const updateExperienceBlock = (experience, index) => {
    setExperienceIndex(index);
    setExperienceData(experience);
  };

  //function for delete experience block
  const onDeleteExperienceBlock = (index) => {
    const filteredExperienceBlock = [...experienceListBlock];
    filteredExperienceBlock.splice(index, 1);
    setExperienceListBlock(filteredExperienceBlock);
  };

  // function for assign experience blocks */
  const getExperienceBlock = (experiences = []) => {
    const experienceArr =
      experiences.length > 0
        ? experiences.map((experience) => {
            let expSavedData = {
              _id: experience?._id,
              companyid: experience.companyid,
              company: experience?.company,
              end: experience?.end,
              position: experience?.position,
              start: experience?.start,
              ctc: experience?.ctc,
              responsibilities: experience?.responsibilities,
            };
            return expSavedData;
          })
        : [];

    setExperienceListBlock(experienceArr);
  };

  //function for edit project block ---------------------------------------
  const updateProjectBlock = (project, index) => {
    setProjectIndex(index);
    setProjectData(project);
  };

  //function for delete project block
  const onDeleteProjectBlock = (index) => {
    const filteredProjectBlock = [...projectListBlock];
    filteredProjectBlock.splice(index, 1);
    setProjectListBlock(filteredProjectBlock);
  };

  //** function for assign project blocks */
  const getProjectBlock = (projects = []) => {
    const projectArr =
      projects.length > 0
        ? projects.map((project) => {
            let projectSavedData = {
              _id: project?._id,
              company: project?.company,
              end: project?.end,
              position: project?.position,
              start: project?.start,
              ctc: project?.ctc,
              technologies: project?.technologies,
              responsibilities: project?.responsibilities,
            };
            return projectSavedData;
          })
        : [];

    setProjectListBlock(projectArr);
  };

  // function for edit eduction block */ -------------------------------------
  const updateEductionBlock = (education, index) => {
    setEducationIndex(index);
    setEducationData(education);
  };

  //** function for delete eduction block from education block list array */
  const onDeleteEducationBlock = (index) => {
    const filteredEducationBlock = [...educationListBlock];
    filteredEducationBlock.splice(index, 1);
    setEducationListBlock(filteredEducationBlock);
  };

  //** function for assign education blocks */
  const getEducationBlock = (educations = []) => {
    const educationArr =
      educations.length > 0
        ? educations.map((education) => {
            let educationSavedData = {
              _id: education?._id,
              degree: education?.degree,
              grade: education?.grade,
              year: education?.year,
              institute: education?.institute,
            };
            return educationSavedData;
          })
        : [];
    setEducationListBlock(educationArr);
  };

  //** function for edit technical experence block */ ----------------------------------
  const updateTechnicalExpBlock = (techExp, index) => {
    settechnicalExperienceData(techExp);
    settechnicalExpIndex(index);
  };

  //** delete tech exp block from expericence list */
  const onDeleteTechExpBlock = (index) => {
    const filteredTechExpBlock = [...technicalExpBlock];
    filteredTechExpBlock.splice(index, 1);
    settechnicalExpBlock(filteredTechExpBlock);
  };

  //** function for view tech exp blocks */
  const getTechExpBlock = (techExp = []) => {
    let techExpArr =
      techExp.length > 0
        ? techExp.map((techExpData) => {
            const companyvalue = {
              label: techExpData?.companyname,
              value: techExpData?.companyname,
            };
            let certificateSavedData = {
              _id: techExpData?._id,
              name: techExpData?.name,
              companyname: techExpData?.companyname,
              companyvalue,
              start: techExpData?.start,
              end: techExpData?.end,
              description: techExpData?.description,
              grade: techExpData?.grade,
            };
            return certificateSavedData;
          })
        : [];
    settechnicalExpBlock(techExpArr);
  };

  //** function for send uploaded doc file url to server or gpt api */
  const getDocumentDataUsingAI = async () => {
    try {
      // show wait while we fetch and map the data from AI
      setFetchingUploadedData(true);

      if (docContent !== "") {
        let aiParams = {
          message: docContent,
          assistantid: assistantId,
          threadid: threadId,
        };

        console.log("aiParams", aiParams);

        let requestUrl =
          url.API_BASE_URL + url.API_CHAT_GPT_CV_ASSISTANT + `?token=${token}`;

        console.log("AI requestUrl", requestUrl);

        const response = await postData(requestUrl, aiParams);

        console.log("gpt response data", response);

        if (response.status) {
          setFetchingUploadedData(false); // stop showing loading

          setAssistantId(response.assistantid); // keep the assistantid for next request
          setThreadId(response.threadid);
          mapCvContent(response.jsoncontent); // add the json content as received for mapping
          setDocContent("");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for set AI data
  const mapCvContent = (cvData) => {
    if (cvData) {
      // setuserImagePath("");
      // setuserImageId(null);
      setCvTitle(cvData.cvtitle);
      setuserName(cvData.name);
      setuserSurname(cvData.surname);
      setuserEmail(cvData.email);
      setuserPhone(cvData.phone);
      setuserPosition(cvData.current_position);
      setcompanyName(cvData.company);
      setUserAddress(cvData.address);
      setUserZipcode(cvData.zipcode);

      setuserSummary(cvData.summary);
      setuserDetails(cvData.details);
      setUserExperienceInYears(cvData.total_experience_in_years);

      //assign skillpoints
      if (cvData.skills) {
        const skillPintArr = cvData?.skills
          ?.filter(
            (item) =>
              (item.name && item.name !== null) ||
              (item.name && item.name !== "")
          )
          .map((skill) => {
            return {
              skill: null,
              skillvalue: null,
              skillname: skill?.name || "",
              points: skill?.score || "0",
              experienceyear: skill?.experience || "0",
            };
          });
        setSkillPointListBlock(skillPintArr);
      }

      //assign experience
      if (cvData.experience) {
        const experienceArr = cvData?.experience
          ?.filter(
            (item) =>
              (item.company && item.company != null) ||
              (item.company && item.company != "")
          )
          .map((experience) => {
            return {
              companyid: null,
              company: experience?.company || "",
              end: experience?.end_year || "",
              position: experience?.position || "",
              start: experience?.start_year || "",
              ctc: experience?.salary || "",
              responsibilities: experience?.responsibilities || "",
            };
          });
        setExperienceListBlock(experienceArr);
      }

      //assign education
      if (cvData.education) {
        const educationArr = cvData?.education
          ?.filter(
            (item) =>
              (item.degree && item.degree !== null) ||
              (item.degree && item.degree != "")
          )
          .map((educationData) => {
            return {
              degree: educationData?.degree,
              grade: educationData?.grade,
              year: educationData?.year,
              institute: educationData?.institute,
            };
          });
        setEducationListBlock(educationArr);
      }

      // assign technical certificates
      if (cvData.certification) {
        const techExpArr = cvData?.certification
          ?.filter(
            (item) =>
              (item.course && item.course != null) ||
              (item.course && item.course != "")
          )
          .map((techExpData) => {
            const companyvalue = {
              label: techExpData?.company || "",
              value: techExpData?.company || "",
            };

            return {
              name: techExpData?.course || "",
              companyname: techExpData?.company || "",
              companyvalue,
              start: techExpData.start_date || "",
              end: techExpData.end_date || "",
              description: techExpData?.description || "",
              grade: techExpData?.grade || "",
            };
          });
        settechnicalExpBlock(techExpArr);
      }

      //assign portfolio links
      if (cvData.portfolio_links) {
        const filteredPortfolioLinks = cvData.portfolio_links.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );
        setuserPortfolioLinks(
          filteredPortfolioLinks.map((item) => ({
            linktext: item,
          }))
        );
      }

      //assign user hobbies
      if (cvData.hobbies) {
        const filteredHobbies = cvData.hobbies.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );

        setuserHobbies(filteredHobbies);
      }

      //assign user roles
      if (cvData.roles) {
        const filteredRoles = cvData.roles.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );

        setuserRoles(
          filteredRoles.map((item) => ({
            rolestext: item,
          }))
        );
      }

      //assign user language
      if (cvData.languages) {
        const filteredLanguages = cvData.languages.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );

        setUserLanguages(
          filteredLanguages.map((item) => ({
            languagetext: item,
          }))
        );
      }

      //assign other skill or soft skill to say
      if (cvData.otherskills) {
        const filteredOtherskills = cvData.otherskills.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );

        setuserOtherSkills(
          filteredOtherskills.map((item) => ({
            skilltext: item,
          }))
        );
      }

      // carrier highlights
      if (cvData.career_highlights) {
        const filteredCarrierHighlights = cvData.career_highlights.filter(
          (item) => (item && item !== null) || (item && item !== "")
        );

        setUserCareerHighlights(
          filteredCarrierHighlights.map((item) => ({
            highlighttext: item,
          }))
        );
      }

      //assign experience
      if (cvData.projects) {
        const projectArr = cvData?.projects
          ?.filter(
            (item) =>
              (item.company && item.company != null) ||
              (item.company && item.company != "")
          )
          .map((experience) => {
            return {
              companyid: null,
              company: experience?.company || "",
              end: experience?.end_year || "",
              position: experience?.role || "",
              start: experience?.start_year || "",
              technologies: experience?.technologies || "",
              responsibilities: experience?.responsibility || "",
            };
          });
        setProjectListBlock(projectArr);
      }
    }
  };

  //** initially set user cv info from local storage */
  const setUserCvDetails = () => {
    setuserImagePath("");
    setuserImageId(null);
    setuserPosition("Applicant");
    setuserEmail("applicant@lobees.com");
    setuserName("Name");
    setuserSurname("Surname");
  };

  //reset all state after save cv
  const resetCVHandler = () => {
    setuserImagePath("");
    setuserImageId(null);
    setUserCvDetails();
    setCvTitle("");
    setuserSummary("");
    setuserDetails("");
    setSkillPointData({
      skill: null,
      skillvalue: null,
      skillname: "",
      points: "",
      experienceyear: "",
    });
    setSkillPointListBlock([]);
    setSkillPointIndex(null);

    setExperienceData({
      companyid: null,
      company: "",
      position: "",
      start: "",
      end: "",
      ctc: "",
      responsibilities: "",
    });
    setExperienceListBlock([]);
    setExperienceIndex(null);

    setProjectData({
      company: "",
      position: "",
      start: "",
      end: "",
      ctc: "",
      technologies: "",
      responsibilities: "",
    });
    setProjectListBlock([]);
    setProjectIndex(null);

    setEducationData({
      degree: "",
      year: "",
      grade: "",
      institute: "",
    });
    setEducationListBlock([]);
    setEducationIndex(null);

    settechnicalExperienceData({
      name: "",
      companyvalue: null,
      companyname: "",
      start: "",
      end: "",
      description: "",
      grade: "",
    });
    settechnicalExpBlock([]);
    settechnicalExpIndex(null);

    setUserLanguages([]);
    setuserCurrency("");
    setuserCurrCtc("");
    setuserExpctCtc("");
    setuserWorkingHours("");
    setuserStartTime("");
    setuserEndTime("");
    setuserCountry("");
    setuserOptionalSkills([]);
    setuserPortfolioLinks([]);
    setuserHobbies([]);
    setuserRoles([]);
    setuserOtherSkills([]);
    setUserCareerHighlights([]);

    setJobTitle("");
    setchallenegeValue(null);
    setchallengeId(null);

    setValidationError({
      cvTitleError: false,
      cvEmailError: false,
      cvNameError: false,
      cvSurnameError: false,
      cvPositonError: false,
      challengeError: false,
    });
  };

  //function for get lead details
  const getLeadDetails = async () => {
    resetCVHandler();
    try {
      setFetchingProfileData(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_LEAD_DETAILS +
        `/${params.id}?token=${token}`;

      let response = await getData(requestUrl);

      setFetchingProfileData(false);

      console.log("Response in lead details", response);

      if (response.status) {
        const data = response.data;

        setCvTitle(data.resumetitle);
        setuserImagePath(data.photoimage?.path ?? "");
        setuserImageId(data.photoimage?._id ?? null);
        setuserPosition(data.position);
        setuserEmail(data.email);
        setuserName(data.name);
        setuserSurname(data.surname);

        setuserSummary(data.resumesummary);
        setuserDetails(data.resumedetails);

        getSkillPointsBlock(data.skillpointidsextended);
        getExperienceBlock(data.experienceids);
        getEducationBlock(data.educationids);
        getTechExpBlock(data.certifications);
        setuserOptionalSkills(data.skills);
        setuserPortfolioLinks(
          data.portfoliolink.map((item) => ({
            linktext: item,
          }))
        );

        setuserHobbies(data.hobbies);

        if (data.experienceids.length > 0) {
          let maxCtc = data.experienceids[0].ctc; // Initialize maxSalary with the salary of the first element

          for (let i = 1; i < data.experienceids.length; i++) {
            if (data.experienceids[i].ctc > maxCtc) {
              maxCtc = data.experienceids[i].ctc; // Update maxSalary if current salary is larger
            }
          }

          setuserCurrCtc(maxCtc);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for search cv
  const getCvDetailsByQuery = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CV_DETAILS_BY_QUERY +
        `?leadid=${params.id}&token=${token}`;

      const response = await getData(requestUrl);

      console.log("response in cv details", response);

      if (response.status) {
        if (response.data) {
          const data = response.data;

          setcvId(data._id);

          setCvTitle(data.resumetitle);
          setuserImagePath(data.cvimagepath ?? "");
          setuserImageId(data.cvimageid ?? null);
          setuserEmail(data.candidateemail);
          setuserName(data.candidatename);
          setuserSurname(data?.candidatesurnname);
          setuserPhone(data.candidatephone);
          setuserPosition(data.candidateposition);
          setUserExperienceInYears(data.experience);

          setuserSummary(data.summary);
          setuserDetails(data.details);

          getSkillPointsBlock(data.cvskillpoint);
          getExperienceBlock(data.cvexperience);
          getProjectBlock(data.projectexperiences);
          getEducationBlock(data.educationids);
          getTechExpBlock(data.certifications);

          setuserCurrency(data.currency);
          setuserCurrCtc(data.currentctc);
          setuserExpctCtc(data.expectedctc);
          setuserWorkingHours(data.weeklyworkhour);
          setuserStartTime(data.starttime);
          setuserEndTime(data.endtime);
          setuserCountry(data.country);
          setuserOptionalSkills(data.optionalskillsarr);
          setuserPortfolioLinks(
            data.portfoliolink.map((item) => ({
              linktext: item,
            }))
          );

          setuserHobbies(data.hobbies);

          setuserRoles(
            data.roles.map((item) => ({
              rolestext: item,
            }))
          );

          setUserLanguages(
            data.languages.map((item) => ({
              languagetext: item,
            }))
          );

          setuserOtherSkills(
            data.otherskills.map((item) => ({
              skilltext: item,
            }))
          );

          setUserCareerHighlights(
            data.carrierhighlights.map((item) => ({
              highlighttext: item,
            }))
          );
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** save cv function */
  const saveCvHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);

      try {
        const savedOptionalSkills = userOptionalSkills.map(
          (item) => item.value
        );

        let userCvData = {
          leadid: params.id,
          candidatename: userName,
          candidatesurnname: userSurname,
          candidateposition: userPosition,
          post: showChallenge ? jobTitle : "",
          candidatephone: userPhone,
          candidateemail: userEmail,
          roles: userRoles,
          resumetitle: cvTitle,
          summary: userSummary,
          details: userDetails,
          skillpointids: skillPointListBlock,
          educationids: educationListBlock,
          experienceinyears: userExperienceInYears,
          experienceids: experienceListBlock,
          projectreferenceids: projectListBlock,
          certificationids: technicalExpBlock,
          optionalskills: savedOptionalSkills,
          otherskills: userOtherSkills,
          languages: userLanguages,
          currentctc: userCurrCtc,
          expectedctc: userExpctCtc,
          currency: userCurrency,
          weeklyworkhour: userWorkingHours,
          starttime: userStartTime,
          endtime: userEndTime,
          country: userCountry,
          portfoliolink: userPortfolioLinks,
          hobbies: userHobbies,
          carrierhighlights: userCarrierHighlights,
          profilepicture: userImageId || null,
          //issaveandapply: isChallangeAvailable,
          challengeid: showChallenge ? challengeId : null,
          isagency: true,
        };

        console.log("update userCvData", userCvData);
        //setisSaving(true);

        let requestUrl = url.API_BASE_URL;
        let response = {};

        console.log(userCvData);

        if (cvId) {
          requestUrl += url.API_UPDATE_CV + `/${cvId}?token=${token}`;

          console.log("reqUrl", requestUrl);

          response = await putData(requestUrl, userCvData);
        } else {
          requestUrl += url.API_CREATE_CV + `?token=${token}`;

          console.log("reqUrl", requestUrl);

          response = await postData(requestUrl, userCvData);
        }

        setIsSaving(false);

        console.log("response in save cv", response);

        if (response.status) {
          if (response.data) {
            setIsJobApplied(response.data.iscvsubmitted);
          } else {
            setIsJobApplied(false);
          }

          let successModal = new bootstrap.Modal(
            document.getElementById("apply_success_mdl")
          );

          successModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setErrorMessage("Please fill up required fields");
    }
  };

  //** use effect for send uploaded doc file url to server
  useEffect(() => {
    if (docContent !== "") {
      getDocumentDataUsingAI();
    }
  }, [docContent]);

  useEffect(() => {
    if (showChallenge) {
      getChallengeList();
    }
  }, [showChallenge]);

  useEffect(() => {
    if (params.id) {
      getCvDetailsByQuery();
    }
  }, [params.id]);

  useEffect(() => {
    if (
      userEmail !== "applicant@lobees.com" ||
      userName !== "Name" ||
      userSurname !== "Surname"
    ) {
      setCvAdded(true);
    } else {
      setCvAdded(false);
    }
  }, [userName, userSurname, userEmail]);

  return (
    <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-1 mb-0">
          <span className="fs-lg fw-semibold">
            {showChallenge
              ? t("Apply to Job/Challenge, with this Lead")
              : t("Add/Update CV to this Lead")}
          </span>
        </div>

        {/* <!-- ========== Start Upper button Section ========== --> */}
        <div className="download_btn d-flex gap-2 mb-3 justify-content-end">
          <button
            className="btn btn-outline-primary d-flex align-items-center"
            onClick={resetCVHandler}
          >
            <i className="material-symbols-outlined icon-fill">refresh</i>
          </button>

          {/* <!-- ========== Start fetch from profile button ========== --> */}
          <button
            className="btn btn-primary d-flex align-items-center gap-1"
            onClick={getLeadDetails}
            disabled={fetchingProfileData ? true : false}
            style={{
              cursor: fetchingProfileData ? "not-allowed" : "pointer",
            }}
          >
            {fetchingProfileData ? (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <i className="material-symbols-outlined icon-fill">person_add</i>
            )}
            {t("Import Cv from Lead")}
          </button>
          {/* <!-- ========== End fetch from profile button ========== --> */}

          {/* <!-- ========== Start fetch from uploaded doc button ========== --> */}
          <button
            className="btn btn-primary d-flex align-items-center gap-1"
            data-bs-toggle="modal"
            data-bs-target="#fetch_from_doc"
            disabled={fetchingUploadedData ? true : false}
            style={{
              cursor: fetchingUploadedData ? "not-allowed" : "pointer",
            }}
          >
            {fetchingUploadedData ? (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <i className="material-symbols-outlined">upload_file</i>
            )}
            {t("Upload New Cv")}
          </button>
          {/* <!-- ========== End fetch from uploaded doc button ========== --> */}
        </div>
        {/* <!-- ========== End Upper button Section ========== --> */}
      </div>

      {/* <div className="accordion mt-2 w-100" id="helpAccordion">
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              How to Apply & Submit Your CV ?
            </button>
          </h3>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#helpAccordion"
          >
            <div className="accordion-body">
              <p>You have two options to submit your CV:</p>
              <ol>
                <li>
                  <strong>From Profile:</strong> Use the CV saved in your
                  profile for quick and easy submission.
                </li>
                <li>
                  <strong>Upload CV:</strong> Upload a new CV file. Our system
                  will parse it and extract the necessary information for you.
                </li>
              </ol>
              <p>Choose the method that works best for you!</p>
            </div>
          </div>
        </div>
      </div> */}

      <form onSubmit={(e) => e.preventDefault()}>
        {/* <!-- ========== Start challenge Section ========== --> */}
        {showChallenge ? (
          <div className="mt-4 mb-3">
            <div className="build_hdng d-flex align-items-center gap-1 mb-3">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">
                {t("Step 1")}
                <span className="mx-1">:</span> {t("Select a Challenge")}
                <span className="me-2"></span>( ** {t("Where you are a member")}{" "}
                )
              </span>
            </div>
            <div className="form-group">
              <Select
                isClearable
                placeholder={t("Select Challenge")}
                options={challengeList}
                value={challenegeValue}
                onChange={(val) => {
                  challengeSelectionHandler(val);
                }}
              />
            </div>
            {validationError.challengeError && (
              <div className="d-flex align-items-center">
                <p className="text-danger fw-semibold">* Select challenege</p>
              </div>
            )}

            <div className="build_hdng d-flex align-items-center gap-1 mb-2 mt-5">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">
                {t("Step 2")}
                <span className="mx-1"> : </span>
                {t("Add/Update CV and Apply")}
              </span>
            </div>
          </div>
        ) : null}
        {/* <!-- ========== End challenge Section ========== --> */}

        {/* <!-- ========== Start job title Section ========== --> */}
        {showChallenge && jobTitle !== "" ? (
          <div className="mt-4 mb-3">
            {/* <div className="build_hdng d-flex align-items-center gap-1 mb-2">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Job Title</span>
            </div> */}
            <div className="build_usrinfo">
              <h3 className="text-center">
                {t("Applying to job")} <span className="mx-1"> : </span>
                {jobTitle}
              </h3>
            </div>
          </div>
        ) : null}
        {/* <!-- ========== End job title Section ========== --> */}

        {/* <!-- ========== Start name, image and email Section ========== --> */}
        <div className="build_innr_row py-4 overflow-hidden">
          <div className="builder_usr_row d-flex justify-content-between">
            <div className="build_usrleft_pnl d-flex gap-3">
              <div
                className="avatar-container position-relative rounded-circle overflow-hidden"
                style={{ width: "120px", height: "120px" }}
              >
                <img
                  src={
                    userImagePath === ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + userImagePath
                  }
                  className="w-100 h-100 object-fit-cover object-center"
                  alt="Expert"
                />
                <Link
                  to="#"
                  onClick={openImageFileInput}
                  id="uploadAvatar"
                  className="block text-center text-white px-3 py-2 position-absolute start-0 end-0 bottom-0"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                  <span className="d-block material-symbols-outlined icon-lg">
                    photo_camera
                  </span>
                </Link>
                <input
                  onChange={imageUploadHandler}
                  ref={imageFileInputRef}
                  type="file"
                  id="uploadedImageFile"
                  className="d-none"
                />
              </div>

              <div className="build_usrinfo">
                <h3 className={`${cvAdded ? "" : "text-gray"}`}>
                  {t("CV Title")}
                  <span className="mx-1"> : </span>
                  {cvTitle}
                </h3>
                <h4 className={`${cvAdded ? "" : "text-gray"}`}>
                  {userName} {userSurname}
                  <span className="d-block">{userPosition}</span>{" "}
                </h4>
                <ul
                  className={`list_stye_none d-flex align-items-center mt-3 gap-4 mb-3 ${
                    cvAdded ? "" : "text-gray"
                  }`}
                >
                  <li className="d-flex align-items-center gap-2">
                    <i className="material-symbols-outlined">email</i>
                    <span>{userEmail}</span>
                  </li>
                  <li className="d-flex align-items-center gap-2">
                    <i className="material-symbols-outlined">phone</i>
                    <span>{userPhone}</span>
                  </li>
                  <li className="d-flex align-items-center gap-2">
                    <i className="material-symbols-outlined">work_history</i>
                    <span>{userExperienceInYears}</span> + Years
                  </li>
                </ul>
              </div>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#personal_info_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="d-flex align-items-center gap-3 mt-1">
            {validationError.cvNameError && (
              <div className="d-flex align-items-center justify-content-end">
                <p className="text-danger fw-semibold">* Name required</p>
              </div>
            )}
            {validationError.cvSurnameError && (
              <div className="d-flex align-items-center justify-content-end">
                <p className="text-danger fw-semibold">* Surname required</p>
              </div>
            )}
            {validationError.cvPositonError && (
              <div className="d-flex align-items-center justify-content-end">
                <p className="text-danger fw-semibold">* Position required</p>
              </div>
            )}
            {validationError.cvEmailError && (
              <div className="d-flex align-items-center justify-content-end">
                <p className="text-danger fw-semibold">* Email required</p>
              </div>
            )}
            {validationError.cvTitleError && (
              <div className="d-flex align-items-center justify-content-end">
                <p className="text-danger fw-semibold">* CV title required</p>
              </div>
            )}
          </div>
        </div>
        {/* <!-- ========== End name, image and email Section ========== --> */}

        {/* <!-- ========== Start roles Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Roles</span>
            </div>
            <div className="build_edit_icon">
              <Link to="#" data-bs-toggle="modal" data-bs-target="#roles_modal">
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>

          <ul className="list_stye_none flex-wrap d-flex gap-2 px-2">
            {userRoles.map((roleData, index) => {
              return (
                <li key={index}>
                  <Link to="#">{roleData.rolestext} , </Link>
                </li>
              );
            })}
          </ul>
        </div>
        {/* <!-- ========== End roles Section ========== --> */}

        {/* <!-- ========== Start Carrier Highlights Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Carrier Highlights</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#carrierHighlights_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <ul className="list_stye_none flex-wrap d-flex gap-2 px-2">
            {userCarrierHighlights.map((highlight, index) => {
              return (
                <li key={index}>
                  <Link to="#">{highlight.highlighttext}, </Link>
                </li>
              );
            })}
          </ul>
        </div>
        {/* <!-- ========== Start Carrier Highlights Section ========== --> */}

        {/* <!-- ========== Start summary Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Summary</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#summery_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="build_para px-2">
            <p>{userSummary}</p>
          </div>
        </div>
        {/* <!-- ========== End summary Section ========== --> */}

        {/* <!-- ========== Start details Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Details</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#details_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="build_para px-2">
            <p dangerouslySetInnerHTML={{ __html: userDetails }}></p>
          </div>
        </div>
        {/* <!-- ========== End details Section ========== --> */}

        {/* <!-- ========== Start skill points Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Skill Points</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#skill_modal"
                onClick={() => {
                  setSkillPointIndex(null);
                }}
              >
                <i className="material-symbols-outlined">add</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {skillPointListBlock.map((skillBlock, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-4"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo"></div>
                    <div className="exp_logo_details">
                      <h4>
                        {skillBlock?.skillname}{" "}
                        <span>with rating {skillBlock?.points}/10 </span>
                      </h4>

                      <h6>
                        Experience
                        <span> {skillBlock?.experienceyear} year</span>
                      </h6>
                    </div>
                  </div>
                  <div className="build_edit_icon">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#skill_modal"
                      onClick={() => {
                        updateSkillPointBlock(skillBlock, index);
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </Link>
                    <button
                      className="d-flex align-items-center gap-1 fs-md fw-semibold text-black border-0 bg-white"
                      onClick={() => {
                        onDeleteSkillPointBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End skill points Section ========== --> */}

        {/* <!-- ========== Start experiences Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Work Experience</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#experience_modal"
                onClick={() => {
                  setExperienceIndex(null);
                }}
              >
                <i className="material-symbols-outlined">add</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {experienceListBlock.map((experience, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-4"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo">
                      {experience.companylogo ? (
                        <img
                          src={
                            experience.companylogo === ""
                              ? assetImages.defaultUser
                              : url.SERVER_URL + experience.companylogo
                          }
                          alt=""
                        />
                      ) : null}
                    </div>
                    <div className="exp_logo_details">
                      <h4>
                        {experience.position}
                        <span>
                          {" "}
                          at{" "}
                          {experience.companyid
                            ? experience.companyid.label
                            : experience.company}
                        </span>
                      </h4>
                      <h6>
                        {experience.start.length > 5
                          ? getDateFormatInSpanish(experience.start) +
                            " - " +
                            getDateFormatInSpanish(experience.end)
                          : experience.start + " - " + experience.end}
                      </h6>
                      <p>{experience.responsibilities}</p>
                    </div>
                  </div>
                  <div className="build_edit_icon">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#experience_modal"
                      onClick={() => {
                        updateExperienceBlock(experience, index);
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        onDeleteExperienceBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End experiences Section ========== --> */}

        {/* <!-- ========== Start project references Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Project References</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#projectreference_modal"
                onClick={() => {
                  setProjectIndex(null);
                }}
              >
                <i className="material-symbols-outlined">add</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {projectListBlock.map((project, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-4"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo"></div>
                    <div className="exp_logo_details">
                      <h4>
                        {project.company}
                        <span>
                          {" "}
                          ||{" "}
                          {project.start.length > 5
                            ? getDateFormatInSpanish(project.start) +
                              " - " +
                              getDateFormatInSpanish(project.end)
                            : project.start + " - " + project.end}
                        </span>
                      </h4>
                      <h6>
                        <p>Role : {project.position}</p>
                        <p>Technologies : {project.technologies}</p>
                      </h6>
                      <h6>{project.responsibilities}</h6>
                    </div>
                  </div>
                  <div className="build_edit_icon">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#projectreference_modal"
                      onClick={() => {
                        updateProjectBlock(project, index);
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        onDeleteProjectBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End project references Section ========== --> */}

        {/* <!-- ========== Start education Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Education</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#education_modal"
                onClick={() => {
                  setEducationIndex(null);
                }}
              >
                <i className="material-symbols-outlined">add</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {educationListBlock.map((education, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-4"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo">
                      <img src="" alt="" />
                    </div>
                    <div className="exp_logo_details">
                      <h4>
                        {education.degree}
                        <span> from {education.institute}</span>
                      </h4>
                      <h6>{education.year}</h6>
                    </div>
                  </div>
                  <div className="build_edit_icon">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#education_modal"
                      onClick={() => {
                        updateEductionBlock(education, index);
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        onDeleteEducationBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End education Section ========== --> */}

        {/* <!-- ========== Start technical certificates  Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Technical Certificates</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#technical_certificate"
                onClick={() => {
                  settechnicalExpIndex(null);
                }}
              >
                <i className="material-symbols-outlined">add</i>
              </Link>
            </div>
          </div>
          <div className="build_experience_details">
            {technicalExpBlock.map((techExpData, index) => {
              return (
                <div
                  className="build_exprience_row d-flex justify-content-between align-items-start pb-4"
                  key={index}
                >
                  <div className="left_exp_info d-flex gap-3 align-items-start">
                    <div className="exp_logo">
                      <img src="images/technical-icon1.png" alt="" />
                    </div>
                    <div className="exp_logo_details">
                      <h4>
                        {techExpData.name}{" "}
                        <p>
                          From {techExpData.companyname}
                          <span> with grade: {techExpData.grade}</span>
                        </p>
                      </h4>
                      <h6>
                        {techExpData.start &&
                          getDateFormatInSpanish(techExpData.start)}{" "}
                        -{" "}
                        {techExpData.end &&
                          getDateFormatInSpanish(techExpData.end)}
                      </h6>
                    </div>
                  </div>
                  <div className="build_edit_icon">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#technical_certificate"
                      onClick={() => {
                        updateTechnicalExpBlock(techExpData, index);
                      }}
                    >
                      <i className="material-symbols-outlined">edit</i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => {
                        onDeleteTechExpBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">delete</i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End technical certificates  Section ========== --> */}

        {/* <!-- ========== Start languages Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Languages</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#language_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="event_tags language_filed">
            <ul className="list_stye_none d-flex align-items-center gap-2">
              {userLanguages.map((language, index) => {
                return (
                  <li key={index}>
                    <Link to="#">{language.languagetext} , </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <!-- ========== End languages Section ========== --> */}

        {/* <!-- ========== Start salary and timings Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Salary and Availability</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#salary_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="gray_bx_outer d-flex gap-2">
            <div className="gray_bx_item">
              <h4 className="mb-2">Current CTC</h4>
              <h6>
                {userCurrCtc} {userCurrency}
              </h6>
            </div>
            <div className="gray_bx_item">
              <h4 className="mb-2">Expected CTC</h4>
              <h6>
                {userExpctCtc} {userCurrency}
              </h6>
            </div>
            <div className="gray_bx_item">
              <h4 className="mb-2">Total Monthly Hours</h4>
              <h6>{userWorkingHours} </h6>
            </div>
            <div className="gray_bx_item">
              <h4 className="mb-2">Availability Timings</h4>
              <h6>
                {userStartTime} - {userEndTime} {userCountry}
              </h6>
            </div>
          </div>
        </div>
        {/* <!-- ========== End salary and timings Section ========== --> */}

        {/* <!-- ========== Start optional soft skills Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Soft Skills</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#otherSkills_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none flex-wrap">
              <div className="event_tags">
                <ul className="list_stye_none flex-wrap d-flex align-items-center gap-2">
                  {userOtherSkills.map((skillData, index) => {
                    return (
                      <li key={index}>
                        <Link to="#">{skillData.skilltext} , </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </ul>
          </div>
        </div>
        {/* <!-- ========== End optional soft skills Section ========== --> */}

        {/* <!-- ========== Start portfolio Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Portfolio Links</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#portfolio_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="event_tags portfolio_links">
            <ul className="list_stye_none d-flex flex-wrap align-items-center gap-2">
              {userPortfolioLinks.map((portfolioLink, index) => {
                return (
                  <li key={index}>
                    <Link to="#" className="d-flex align-items-center gap-2">
                      <i>
                        <img src="images/portfolio-link-icon1.png" alt="" />
                      </i>
                      <span>{portfolioLink.linktext} , </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <!-- ========== End portfolio Section ========== --> */}

        {/* <!-- ========== Start hobbies Section ========== --> */}
        <div className="build_innr_row py-4">
          <div className="build_hdng_row d-flex align-items-center justify-content-between gap-2 mb-4">
            <div className="build_hdng d-flex align-items-center gap-1">
              <i className="material-symbols-outlined">drag_indicator</i>
              <span className="fs-lg fw-semibold">Hobbies</span>
            </div>
            <div className="build_edit_icon">
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#hobbies_modal"
              >
                <i className="material-symbols-outlined">edit</i>
              </Link>
            </div>
          </div>
          <div className="event_tags">
            <ul className="list_stye_none flex-wrap d-flex align-items-center gap-2">
              {userHobbies.map((hobbiesData, index) => {
                return (
                  <li key={index}>
                    <Link to="#">{hobbiesData} , </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <!-- ========== End hobbies Section ========== --> */}

        {/* <!-- ========== Start apply and submit section component ========== --> */}
        <div className="action d-flex flex-wrap align-items-center justify-content-between mt-4">
          <Link className="btn btn-outline-primary" to={previousUrl}>
            Back
          </Link>
          <button
            className="btn btn-primary"
            type="button"
            onClick={saveCvHandler}
            disabled={isSaving}
            style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
          >
            {isSaving && (
              <span
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <i className="visually-hidden">Loading...</i>
              </span>
            )}

            {showChallenge
              ? t("Apply with this Resume")
              : t("Save this Resume")}
          </button>
        </div>
        {/* <!-- ========== End apply and submit section component ========== --> */}

        {errorMessage === "" ? null : (
          <div className="d-flex align-items-center justify-content-end mt-2">
            <p className="text-danger fw-semibold">* {errorMessage}</p>
          </div>
        )}
      </form>

      {/* <!-- ========== Start modal Section ========== --> */}
      <FetchFromDocModal
        token={token}
        url={url}
        uploadSingleFile={uploadSingleFile}
        setUploadedDocUrl={setUploadedDocUrl}
        setDocContent={setDocContent}
      />

      <PersonalInfoModal
        setErrorMessage={setErrorMessage}
        setCvTitle={setCvTitle}
        setuserName={setuserName}
        setuserSurname={setuserSurname}
        setuserEmail={setuserEmail}
        setuserPosition={setuserPosition}
        setuserPhone={setuserPhone}
        setUserExperienceInYears={setUserExperienceInYears}
        cvTitle={cvTitle}
        userName={userName}
        userSurname={userSurname}
        userEmail={userEmail}
        userPosition={userPosition}
        userPhone={userPhone}
        userExperienceInYears={userExperienceInYears}
      />

      <RolesModal userRoles={userRoles} setuserRoles={setuserRoles} />

      <CarrierHighlightsModal
        userCarrierHighlights={userCarrierHighlights}
        setUserCareerHighlights={setUserCareerHighlights}
      />

      <SummaryModal setuserSummary={setuserSummary} userSummary={userSummary} />

      <DetailsModal setuserDetails={setuserDetails} userDetails={userDetails} />

      <SkillPointModal
        setSkillPointData={setSkillPointData}
        setSkillPointIndex={setSkillPointIndex}
        setSkillPointListBlock={setSkillPointListBlock}
        skillPointData={skillPointData}
        skillPointIndex={skillPointIndex}
        skillPointListBlock={skillPointListBlock}
      />

      <ExperienceModal
        experienceData={experienceData}
        setExperienceData={setExperienceData}
        experienceListBlock={experienceListBlock}
        setExperienceListBlock={setExperienceListBlock}
        experienceIndex={experienceIndex}
        setExperienceIndex={setExperienceIndex}
      />

      <ProjectReferencesModal
        projectData={projectData}
        setProjectData={setProjectData}
        projectListBlock={projectListBlock}
        setProjectListBlock={setProjectListBlock}
        projectIndex={projectIndex}
        setProjectIndex={setProjectIndex}
      />

      <EducationModal
        educationIndex={educationIndex}
        setEducationIndex={setEducationIndex}
        educationData={educationData}
        setEducationData={setEducationData}
        educationListBlock={educationListBlock}
        setEducationListBlock={setEducationListBlock}
      />

      <TechnicalCertificateModal
        technicalExpIndex={technicalExpIndex}
        settechnicalExpIndex={settechnicalExpIndex}
        technicalExperienceData={technicalExperienceData}
        settechnicalExperienceData={settechnicalExperienceData}
        technicalExpBlock={technicalExpBlock}
        settechnicalExpBlock={settechnicalExpBlock}
      />

      <LanguageModal
        userLanguages={userLanguages}
        setUserLanguages={setUserLanguages}
      />

      <SalaryModal
        setuserCurrency={setuserCurrency}
        setuserCurrCtc={setuserCurrCtc}
        setuserExpctCtc={setuserExpctCtc}
        setuserWorkingHours={setuserWorkingHours}
        setuserStartTime={setuserStartTime}
        setuserEndTime={setuserEndTime}
        setuserCountry={setuserCountry}
        userCurrency={userCurrency}
        userCurrCtc={userCurrCtc}
        userExpctCtc={userExpctCtc}
        userWorkingHours={userWorkingHours}
        userStartTime={userStartTime}
        userEndTime={userEndTime}
        userCountry={userCountry}
      />

      {/* soft skills  */}
      <OtherSkillsModal
        userOtherSkills={userOtherSkills}
        setuserOtherSkills={setuserOtherSkills}
      />

      <PortfolioModal
        userPortfolioLinks={userPortfolioLinks}
        setuserPortfolioLinks={setuserPortfolioLinks}
      />

      <HobbiesModal userHobbies={userHobbies} setuserHobbies={setuserHobbies} />

      <ApplySuccessModal
        isApplied={isJobApplied}
        jobTitle={jobTitle}
        showChallenge={showChallenge}
        // isSaveChallangeAvailable={challengeId?true:false}
        afterModalClose={() => {
          setIsJobApplied(false);
          setJobTitle("");
          setchallenegeValue(null);
          setchallengeId(null);
        }}
      />

      {/* <!-- ========== End modal Section ========== --> */}
    </div>
  );
};

export default SaveLeadCvForm;
