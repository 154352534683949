/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import * as url from "helper/UrlHelper";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box, Tooltip, IconButton } from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";

const CurrentProjectList = ({ isLoading = false, projectList = [] }) => {
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: t("Title"),
        size: 250,
      },
      {
        accessorKey: "owner",
        header: t("Owner"),
        size: 300,
        Cell: ({ row }) => (
          <div className="owner border-bottom-0 d-flex gap-1">
            <img
              className="rounded-circle"
              height={40}
              width={40}
              src={
                row.original.ownerimage == ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + row.original.ownerimage
              }
              alt="Babcock"
            />
            <p>
              <span className="d-block">{row.original.owner}</span>
              <span className="d-block">{row.original.owneremail}</span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: "startdate",
        header: t("Start Date"),
      },
      {
        accessorKey: "enddate",
        header: t("End Date"),
      },

      {
        accessorKey: "membercount",
        header: t("Members"),
      },
      {
        accessorKey: "taskcount",
        header: t("Tasks"),
      },
      {
        accessorKey: "loghours",
        header: t("Log Hours"),
      },
      {
        accessorKey: "totalcost",
        header: t("Cost"),
      },

    ],
    [projectList, i18n.language]
  );

  return (
    <div className="rounded-10 shadow-sm p-3 bg-white mb-4">
      <div className="filter-container py-3">
        <div className="row align-items-center">
          <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
            <h3>Project</h3>
          </div>
          <div className="col-md-7 col-lg-6 d-flex justify-content-end">
            <div className="report_slect_container d-flex justify-content-end w-100  gap-1 gap-sm-2">
              {/* <Select /> */}

              {/* <Link
                to="#"
                className="btn btn-gray d-flex align-items-center gap-1"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasFilter"
                aria-controls="offcanvasFilter"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  tune
                </span>
                <span className="d-block">Filter</span>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="table-wrapper report_table">
        <div className="table-responsive mb-4">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={projectList} // data from api to be displayed
            enableGrouping
            // enableRowSelection // enable showing checkbox
            getRowId={(row) => row._id} // map which value to select with row checkbox
            // onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            // state={{ rowSelection }} //pass our managed row selection state to the table to use
            // enableRowActions // show the action column
            // renderRowActionMenuItems={({ row }) => [
            //   <MenuItem key="view">
            //     <Link
            //       className="dropdown-item"
            //       to={`/admin/projectmanagement/details/${row.id}`}
            //     >
            //       {t("View Details")}
            //     </Link>
            //   </MenuItem>,
            //   <MenuItem key="copy">
            //     <Link
            //       className="dropdown-item"
            //       to={`/admin/projectmanagement/copyproject/${row.id}`}
            //     >
            //       {t("Copy Project")}
            //     </Link>
            //   </MenuItem>,
            //   <MenuItem key="member">
            //     <Link
            //       className="dropdown-item"
            //       to={`/admin/projectmanagement/members/${row.id}`}
            //     >
            //       {t("View Members")}
            //     </Link>
            //   </MenuItem>,
            //   <MenuItem key="task">
            //     <Link
            //       className="dropdown-item"
            //       to={`/admin/projectmanagement/tasksection/${row.id}`}
            //     >
            //       {t("View Task")}
            //     </Link>
            //   </MenuItem>,
            //   <MenuItem key="log">
            //     <Link
            //       className="dropdown-item"
            //       to={`/admin/projectmanagement/logs/${row.id}`}
            //     >
            //       {t("View Logs")}
            //     </Link>
            //   </MenuItem>,
            // ]} // action columns menu items
            defaultColumn={{
              minSize: 20,
              maxSize: 300,
              size: 50, //make columns wider by default
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: "60vh",
              },
            }}
            enableStickyHeader
          />
        </div>
      </div>
    </div>
  );
};

export default CurrentProjectList;
