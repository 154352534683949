/* eslint-disable */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import ProjectManagementCommonHeader from "components/ProjectManagementComponents/Common/Header/ProjectManagementCommonHeader";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

import ProjectChart from "../DashboardSections/ProjectChart";
import ProjectGraph from "../DashboardSections/ProjectGraph";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import CurrentProjectList from "../DashboardSections/CurrentProjectList";
import ProjectCard from "components/ProjectManagementComponents/Common/Cards/ProjectCard";
import TaskCard from "components/ProjectManagementComponents/Common/Cards/TaskCard";
import InvoiceCard from "components/ProjectManagementComponents/Common/Cards/InvoiceCard";

const ProjectDashboardBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t } = useTranslation();
  const breadcrumbTxt = [{ title: t("Project") + ` / ` + t("Dashboard") }];

  const [isLoading, setIsLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);

  const [projectDataLoading, setProjectDataLoading] = useState(false);
  const [taskDataLoading, setTaskDataLoading] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [totalProjectCount, setTotalProjectCount] = useState(0);
  const [lastmonthProjectCount, setLastmonthProjectCount] = useState(0);
  const [currentmonthProjectCount, setCurrentmonthProjectCount] = useState(0);

  const [totalTaskCount, setTotalTaskCount] = useState(0);
  const [lastmonthTaskCount, setLastmonthTaskCount] = useState(0);
  const [currentmonthTaskCount, setCurrentmonthTaskCount] = useState(0);

  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);



  //function for get project list
  const getProjectList = async () => {
    try {
      setIsLoading(true);

      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_PROJECTS}?token=${token}&userrolestring=${userInfo.role.slug}`;

      const response = await getData(requestUrl);

      // console.log("projects >>>", response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");

        setProjectList(response.data.slice(0, 10));
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for get project data for dashboard
  const getProjectData = async () => {
    try {
      setProjectDataLoading(true);

      let requestUrl = `${url.API_BASE_URL}${url.API_GET_PROJECTDATA_DASHBOARD}?token=${token}&userrolestring=${userInfo.role.slug}`;

      const response = await getData(requestUrl);

      console.log("project dashboard data response >>>", response);

      setProjectDataLoading(false);

      if (response.status) {

        setTotalProjectCount(response.data.projectList?.length??0);
        setLastmonthProjectCount(response.data.lastMonthProjects?.length??0);
        setCurrentmonthProjectCount(response.data.currentMonthProjects?.length??0);

      } else {
        setMessageType("error");
      }

      //setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for get task data for dashboard
  const getTaskData = async () => {
    try {
      setTaskDataLoading(true);

      let requestUrl = `${url.API_BASE_URL}${url.API_GET_TASKDATA_DASHBOARD}?token=${token}&userrolestring=${userInfo.role.slug}`;

      const response = await getData(requestUrl);

      console.log("task dashboard data response >>>", response);

      setTaskDataLoading(false);

      if (response.status) {

        setTotalTaskCount(response.data.taskInfo?.tasks?.length??0);
        setLastmonthTaskCount(response.data.lastMonthTasks?.length??0);
        setCurrentmonthTaskCount(response.data.currentMonthTasks?.length??0);

        setTotalInvoiceAmount(response.data.taskInfo?.totalTaskPrice??0);
        
      } else {
        setMessageType("error");
      }

      //setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  useEffect(() => {
    getProjectList();
    getProjectData();
    getTaskData();
  }, []);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <section className="projects-wrapper bg-white pb-5">
      <ProjectManagementCommonHeader componentName="dashboard" />

      <div className="container-fluid px-lg-5">
        <BreadCrumb breadCrumbText={breadcrumbTxt} bottom={true} />

        {/* <!-- ========== Start upper card Section ========== --> */}
        <div className="row mb-4">
          <ProjectCard 
            totalProjectCount={totalProjectCount}
            lastmonthProjectCount={lastmonthProjectCount}
            currentmonthProjectCount={currentmonthProjectCount}
            projectDataLoading={projectDataLoading}
          />

          <TaskCard
            totalTaskCount={totalTaskCount}
            lastmonthTaskCount={lastmonthTaskCount}
            currentmonthTaskCount={currentmonthTaskCount}
            taskDataLoading={taskDataLoading}
          />

          <InvoiceCard 
            totalInvoiceAmount={totalInvoiceAmount}
            taskDataLoading={taskDataLoading}
          />
        </div>
        {/* <!-- ========== End upper card Section ========== --> */}

        <ProjectGraph />

        <CurrentProjectList isLoading={isLoading} projectList={projectList} />

        <ProjectChart />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    </section>
  );
};

export default ProjectDashboardBody;
