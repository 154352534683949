/* eslint-disable */
import React, { useEffect } from "react";

//** import components */
import Header from "components/Common/Header/Header";
import DashboardOldBody from "components/DashboardComponents/DashboardOld/DashboardOldBody";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import DashboardReportIssueOldModal from "components/Common/Modal/DashboardReportIssueOldModal";
import DashboardSendOfferOldModal from "components/Common/Modal/DashboardSendOfferOldModal";

const DashboardOld = () => {
  useEffect(() => {
    document.title = "Dashboard old";
  }, []);

  return (
    <main id="app">
      <Header moduleName="dashboard" />
      <DashboardOldBody />

      {/* ------ all modal render here ------ */}
      <AddLabelModal moduleName="dashboard" />

      <DashboardReportIssueOldModal />
      <DashboardSendOfferOldModal />
    </main>
  );
};

export default DashboardOld;
