/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

// import { Editor } from "@tinymce/tinymce-react";
import JoditEditor from "jodit-react";

import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import {
  getData,
  postData,
  putData,
  uploadMultipleFile,
  uploadSingleFile,
} from "utils/Gateway";
import * as url from "helper/UrlHelper";

// clipboardCopy for mail variables
import clipboardCopy from "clipboard-copy";

// import mail variables from json file
import mailVariables from "data/Prod/MailVariables.json";
import colorCodes from "data/Prod/Colours.json";

//import other components
import downloadFileHandler from "helper/DownloadFile";
import { joditEditorConfig } from "Config/Config";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import SuccessTemplateModal from "../Modal/SuccessTemplateModal";

//image resize for editor
import { resizeOrCompressImage } from "helper/Common/CommonHelper";
import AlertNotification from "../AlertNotification/AlertNotification";

const MailTemplateForm = ({ moduleName, breadcrumbText, afterModalClose }) => {
  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const params = useParams();

  //** Language translation */
  const { t } = useTranslation();

  //option for all survey for seclect
  const [allSurveyList, setAllSurveyList] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);

  /*======== form requirement start ==========*/
  const [mailTemplateName, setMailTemplateName] = useState("");
  const [mailTemplateSlug, setMailTemplateSlug] = useState("");
  const [mailTemplateSubject, setMailTemplateSubject] = useState("");
  const [mailTemplateIsPublic, setMailTemplateIsPublic] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadIds, setFileUploadIds] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [mailDescription, setMailDescription] = useState(""); //for ck editor
  /*======== form requirement end ==========*/

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const editor = useRef(null);

  // const editorCustomConfig = joditEditorConfig();
  const editorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 400,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestUrl =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestUrl, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setMailDescription(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const editorConfig = useMemo(() => editorCustomConfig, []);

  const colorOptionStyle = {
    display: "flex",
    alignItems: "center",
  };

  const colorBoxStyle = {
    width: "20px",
    height: "20px",
    marginRight: "10px",
    border: "1px solid #000",
  };

  /*=========== form repeater section start ===========*/

  //template for action link block
  const actionLinkTemplate = {
    name: "",
    color: null,
    colordata: null,
    externallink: "",
    surveyvalue: null,
    surveydata: null,
  };

  //** Requirement for action link block */
  const [actionLinkBlock, setActionLinkBlock] = useState([]);

  //link tracker block
  const linkTrackerTemplate = {
    link: "",
    label: "",
  };

  const [linkTrackerBlock, setlinkTrackerBlock] = useState([]);

  //** Hooks for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
    subjectWarning: false,
    linkNameWarning: false,
    linkColorWarning: false,
    linkSurveyWarning: false,
    trackerLinkWarning: false,
    trackerLabelWarning: false,
  });

  const [isSaving, setIsSaving] = useState(false);

  //** Function to navigate to survey question */
  const getAllSurvey = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_SURVEY + `?token=${token}`;

      const response = await getData(requestUrl);
      // console.log(response);

      if (response.status) {
        setAllSurveyList(
          response.data.map((survey) => ({
            label: survey.name,
            value: survey._id,
          }))
        );
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //** Function for addign action link section */
  const addActionLinkBlock = () => {
    setActionLinkBlock([...actionLinkBlock, actionLinkTemplate]);
  };

  //** Function for delete action link block */
  const onDeleteActionLinkBlock = (index) => {
    const filteredActionLinkBlock = [...actionLinkBlock];
    filteredActionLinkBlock.splice(index, 1);
    setActionLinkBlock(filteredActionLinkBlock);
  };

  //** Function for change survey select */
  const changeActionLinkColorSelect = (index, field, field2, value) => {
    //     console.log("field", field);
    //     console.log("field2", field2);
    //     console.log("value", value);
    const updatedFormValues = actionLinkBlock.map((block, i) =>
      index == i
        ? Object.assign(block, { [field]: value, [field2]: value.value })
        : block
    );
    setActionLinkBlock(updatedFormValues);
  };

  //** Function for change survey select */
  const changeActionLinkSurveySelect = (index, field, field2, value) => {
    const updatedFormValues = actionLinkBlock.map((block, i) =>
      index == i
        ? Object.assign(block, { [field]: value, [field2]: value.value })
        : block
    );
    setActionLinkBlock(updatedFormValues);
  };

  //** Function for other action link input field */
  const changeActionLinkInputs = (index, field, value) => {
    const updatedFormValues = actionLinkBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );
    // console.log(`updatedFormValues`, updatedFormValues);
    setActionLinkBlock(updatedFormValues);
  };

  //** Function for mapping form repeater data */
  const updatedActionLinkBlock = (actionLink) => {
    const actionLinkArr = actionLink.map((actionLinkData) => {
      return {
        _id: actionLinkData?._id,
        name: actionLinkData?.name,
        color: actionLinkData?.color,
        colordata: getColorcodeLabel(actionLinkData.color),
        externallink: actionLinkData?.externallink,
        surveyvalue: actionLinkData?.survey,
        surveydata: actionLinkData?.survey?.value,
      };
    });
    // console.log("actionLinkArr >>>>>> ", actionLinkArr);
    setActionLinkBlock(actionLinkArr);
  };

  const getColorcodeLabel = (colorCode) => {
    return {
      label: (
        <div style={colorOptionStyle}>
          <div style={{ ...colorBoxStyle, backgroundColor: colorCode }} />
        </div>
      ),
      value: colorCode,
    };
  };
  /*=========== form repeater section end ===========*/

  //for adding a new link tracker block
  const onAddLinkTrackerBlock = () => {
    setlinkTrackerBlock([...linkTrackerBlock, linkTrackerTemplate]);
  };

  //** Function for delete link tracker block */
  const onDeleteLinkTrackerBlock = (index) => {
    const filteredLinkTrackerBlock = [...linkTrackerBlock];
    filteredLinkTrackerBlock.splice(index, 1);
    setlinkTrackerBlock(filteredLinkTrackerBlock);
  };

  //** Function for change link tracker value */
  const changeLinkTrackerValue = (index, field, value) => {
    const updatedFormValues = linkTrackerBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );
    setlinkTrackerBlock(updatedFormValues);
  };

  //** Function for mapping form repeater data */
  const updatedLinkTrackerBlock = (linkTracker) => {
    const linkTrackerArr = linkTracker.map((linkTrackerData) => {
      return {
        _id: linkTrackerData?._id,
        link: linkTrackerData?.link,
        label: linkTrackerData?.label,
      };
    });
    // console.log("linkTrackerArr >>>>>> ", linkTrackerArr);
    setlinkTrackerBlock(linkTrackerArr);
  };

  //** Function for file upload */
  const uploadHandler = async (e) => {
    try {
      setIsUploading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      if (response.status) {
        // console.log(
        //   `response in file upload mail template------>`,
        //   response.data
        // );
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );
        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/
        setIsUploading(false);
        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));
        //check old file ids are exist or not.If exist then new id will concat with old id
        if (fileUploadIds.length == 0) {
          setFileUploadIds(fileIds);
        } else {
          let fileArr = fileUploadIds.concat(fileIds);
          setFileUploadIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
        resetFile();
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //** Function for delete file from upload stack */
  const closeFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...fileUploadIds];
    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);
    setUploadedFiles(closedFile);
    setFileUploadIds(removedFileId);
    resetFile();
  };

  //** Function for get template details */
  const getTemplateDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TEMPLATE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        setMailTemplateName(response.data.name);
        setMailTemplateSlug(response.data.slug);
        setMailTemplateSubject(response.data.subject);
        //   setMailTemplateIsPublic(response.data.ispublic);
        setMailDescription(response.data.description);
        if (response.data.actionlinks.length > 0) {
          updatedActionLinkBlock(response.data?.actionlinks);
        }
        /*----- assign uploaded files with path for display start ------*/
        if (
          response.data.linktrackerids &&
          response.data.linktrackerids.length > 0
        ) {
          updatedLinkTrackerBlock(response.data.linktrackerids);
        }

        const uploadedFileValues = response.data?.uploads.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );
        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/
        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data?.uploads?.map(({ _id, ...other }) => ({
          _id,
        }));
        //check old file ids are exist or not.If exist then new id will concat with old id
        if (fileUploadIds.length == 0) {
          setFileUploadIds(fileIds);
        } else {
          let fileArr = fileUploadIds.concat(fileIds);
          setFileUploadIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //** Validate function */
  const validationHandler = () => {
    let isValid = true;
    if (mailTemplateName === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }
    if (mailTemplateSlug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }
    if (mailTemplateSubject === "") {
      setValidation((prevState) => ({ ...prevState, subjectWarning: true }));
      isValid = false;
    }
    /* add survey link validation ------------- */
    if (actionLinkBlock.length > 0) {
      actionLinkBlock.forEach((link) => {
        if (link.name === "") {
          setValidation((prevState) => ({
            ...prevState,
            linkNameWarning: true,
          }));
          isValid = false;
        }
        if (link.color === "") {
          setValidation((prevState) => ({
            ...prevState,
            linkColorWarning: true,
          }));
          isValid = false;
        }
        // if (link.surveyvalue === null) {
        //   setValidation((prevState) => ({
        //     ...prevState,
        //     linkSurveyWarning: true,
        //   }));
        //   isValid = false;
        // }
      });
    }

    //add link tracker validation
    if (linkTrackerBlock.length > 0) {
      linkTrackerBlock.forEach((tracker) => {
        if (tracker.link === "") {
          setValidation((prevState) => ({
            ...prevState,
            trackerLinkWarning: true,
          }));
          isValid = false;
        }
        if (tracker.label === "") {
          setValidation((prevState) => ({
            ...prevState,
            trackerLabelWarning: true,
          }));
          isValid = false;
        }
      });
    }

    return isValid;
  };

  //** Function for save mail template */
  const saveMailTemplateHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);

      try {
        let templateData = {
          status: "1",
          name: mailTemplateName,
          slug: mailTemplateSlug,
          subject: mailTemplateSubject,
          //     ispublic: mailTemplateIsPublic.toString(),
          ispublic: moduleName === "CRM" ? "false" : "true",
          description: mailDescription,
          actionlinks: actionLinkBlock,
          linktrackers: linkTrackerBlock,
          uploads: fileUploadIds,
        };

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (params.id) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_MAIL_TEMPLATE +
            `/${params.id}?token=${token}`;

          response = await putData(requestUrl, templateData);
        } else {
          requestUrl =
            requestUrl + url.API_CREATE_MAIL_TEMPLATE + `?token=${token}`;

          response = await postData(requestUrl, templateData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          resetHandler();
          setMessageType("success");
          const bootstrapModal = new bootstrap.Modal(
            document.getElementById("afterSaveTemplateModal")
          );

          bootstrapModal.show();
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please fill up required fields");
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //** Function for clear file value */
  const resetFile = () => {
    const file = document.getElementById("uploadedMailTemplateFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  /* reset */
  const resetHandler = () => {
    setMailTemplateName("");
    setMailTemplateSlug("");
    setMailTemplateSubject("");
    setMailDescription("");
    //     setMailTemplateIsPublic(false);
    setFileUploadIds([]);
    setUploadedFiles([]);
    resetFile();
    setActionLinkBlock([]);
    setlinkTrackerBlock([]);
    setValidation({
      nameWarning: false,
      slugWarning: false,
      subjectWarning: false,
      linkNameWarning: false,
      linkColorWarning: false,
      linkSurveyWarning: false,
    });
  };

  const getOptionsFromColorCodeJson = () => {
    return colorCodes.map((color) => ({
      label: (
        <div style={colorOptionStyle}>
          <div style={{ ...colorBoxStyle, backgroundColor: color.code }} />
        </div>
      ),
      value: color.code,
    }));
  };

  useEffect(() => {
    if (params.id) {
      getTemplateDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getAllSurvey();
    const options = getOptionsFromColorCodeJson();
    setColorOptions(options);
  }, []);

  //* This is a function that is called when the alert close button is clicked.
  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  return (
    <div className="container-fluid px-lg-5">
      <BreadCrumb breadCrumbText={breadcrumbText} />
      <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm mt-2">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="fields-container flex-fill">
            {/* ------- template name start ------ */}
            <div className="form-group mb-4">
              <label
                htmlFor="availabilityHours"
                className="text-nowrap me-lg-3"
              >
                {t("Template Name")}
              </label>
              <input
                type="text"
                className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                value={mailTemplateName}
                onChange={(e) => {
                  setMailTemplateName(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    nameWarning: false,
                  }));
                }}
              />
              {/* name warning */}
              {validation.nameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter a template name!")}</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------- template name end ------ */}

            {/* ------- template slug start ------ */}
            <div className="form-group mb-4">
              <label
                htmlFor="availabilityHours"
                className="text-nowrap me-lg-3"
              >
                {t("Slug")}
              </label>
              <input
                type="text"
                className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                value={mailTemplateSlug}
                onChange={(e) => {
                  setMailTemplateSlug(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    slugWarning: false,
                  }));
                }}
              />
              {/* stug warning */}
              {validation.slugWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter a slug!")}</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------- template slug end ------ */}
            {/* ------- template subject start ------ */}
            <div className="form-group mb-4">
              <label
                htmlFor="availabilityHours"
                className="text-nowrap me-lg-3"
              >
                {t("Subject")}
              </label>
              <input
                type="text"
                className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                value={mailTemplateSubject}
                onChange={(e) => {
                  setMailTemplateSubject(e.target.value),
                    setValidation((prevState) => ({
                      ...prevState,
                      subjectWarning: false,
                    }));
                }}
              />
              {/* subject warning */}
              {validation.subjectWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter a subject!")}</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------- template subject end ------ */}
            {/* ------- template mail variable select start ------ */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="" className="text-nowrap me-lg-3">
                    {t("Mail Variable")}
                  </label>
                  <Select
                    className=""
                    placeholder={t("Select Mail Variable")}
                    options={mailVariables}
                    onChange={(event) => {
                      clipboardCopy(event.value);
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center p-lg-3 body-lg-bg rounded-10">
                    {t(
                      "Select the mail variable you want to add. Next just paste where you want it use it."
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* ------- template mail variable select end ------ */}
            {/* ----- is public section start ----- */}
            {/* <div className="form-group mb-4">
              <label>{t("Is public")}</label>
              <div className="form-check">
                <input
                  className="form-check-input "
                  type="checkbox"
                  name="ispublic"
                  id="ispublic"
                  checked={mailTemplateIsPublic}
                  onChange={(e) => setMailTemplateIsPublic(e.target.checked)}
                />
              </div>
            </div> */}
            {/* ----- is public section end ----- */}

            {/* ----- action button heading text section start ----- */}
            {/* <div className="form-group mb-3">
              <div className="row">
                <div className="col-6">
                  <label>{t("Add Action Buttons in mail")}</label>
                </div>
              </div>
            </div> */}
            {/* ----- action button heading text section end ----- */}
            {/* ------- form repeater sction for action link start -------- */}
            {/* {actionLinkBlock.map((block, index) => {
              return               <div
                className="form-group p-3 bg-gray-500 mb-3 action_link_block_bg"
                key={index}
              >
                <div className="row">
                  <div className="col-12 d-flex align-items-center justify-content-end">
                    <Link
                      to="#"
                      className="text-secondary mb-2"
                      onClick={() => {
                        onDeleteActionLinkBlock(index);
                      }}
                    >
                      <span className="material-symbols-outlined">close</span>
                    </Link>
                  </div>
                 </div>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label>{t("Button text")}</label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      placeholder={t("Enter name")}
                      value={block.name}
                      onChange={(e) => {
                        changeActionLinkInputs(index, "name", e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          linkNameWarning: false,
                        }));
                      }}
                    />
                    {validation.linkNameWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter a survey name!")}</span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label>{t("Button Colour")}</label>
                    <Select
                      className="nosearch_select"
                      placeholder={t("Select color")}
                      options={colorOptions}
                      value={block.colordata}
                      onChange={(value) => {
                        changeActionLinkColorSelect(
                          index,
                          "colordata",
                          "color",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label>{t("Add Survey")}</label>
                    <Select
                      className="nosearch_select"
                      placeholder={t("Select survey")}
                      options={allSurveyList}
                      value={block.surveyvalue}
                      onChange={(value) => {
                        changeActionLinkSurveySelect(
                          index,
                          "surveyvalue",
                          "surveydata",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label>{t("Button Slug")}</label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      placeholder={t("Current Link Slug")}
                      value={block.externallink}
                      onChange={(e) => {
                        changeActionLinkInputs(
                          index,
                          "externallink",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            })} */}
            {/* ------- form repeater sction for action link end -------- */}
            {/* ------- add action link button start -------- */}
            {/* <div className="form-group mb-3">
              <div className="row">
                <div className="col-3">
                  <Link
                    onClick={addActionLinkBlock}
                    to="#"
                    className="d-flex align-items-center gap-1 text-primary fw-medium"
                  >
                    <span className="d-block material-symbols-outlined">
                      add
                    </span>
                    <span>{t("Add Action Button")}</span>
                  </Link>
                </div>
              </div>
            </div> */}
            {/* ------- add action link button end -------- */}

            {/* ------- editor section start ------- */}
            <div className="form-group mb-4">
              <label>{t("Details")}</label>
              {/* <Editor
                apiKey={editorConfig}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | |link code alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat  | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                  //------------ Image paste in editor --------------------
                  paste_data_images: true,
                  paste_preprocess: function (editor, args) {
                    // Check if the content being pasted contains images

                    if (args.content) {
                      // Extract image data from blob URLs
                      const blobUrls = args.content.match(/(blob:[^'"]+)/g);

                      // Iterate over each blob URL and process the image
                      if (blobUrls) {
                        Promise.all(
                          blobUrls.map(async (blobUrl) => {
                            let urlToReplace = "";

                            // Convert blob URL to data URL
                            return fetch(blobUrl)
                              .then((response) => response.blob())
                              .then((blob) => {
                                const reader = new FileReader();
                                return new Promise((resolve, reject) => {
                                  reader.onload = function () {
                                    // the large blob url which will be replace by the new one
                                    urlToReplace = reader.result;

                                    // Resize or compress the image
                                    resizeOrCompressImage(
                                      reader.result,
                                      "300",
                                      "300"
                                    )
                                      .then((resizedImageData) => {
                                        // Generate a new blob URL for the modified image data
                                        const modifiedBlobUrl =
                                          URL.createObjectURL(resizedImageData);

                                        // Replace the original blob URL with the modified blob URL in the content
                                        const editorCurrentContent =
                                          tinymce.activeEditor.getContent();

                                        args.content =
                                          editorCurrentContent.replace(
                                            urlToReplace,
                                            modifiedBlobUrl
                                          );

                                        resolve();
                                      })
                                      .catch((error) => {
                                        console.error(
                                          "Error compressing image:",
                                          error
                                        );
                                        reject(error);
                                      });
                                  };
                                  reader.readAsDataURL(blob);
                                });
                              });
                          })
                        )
                          .then(() => {
                            // After all images are processed, set the updated content in the editor
                            tinymce.activeEditor.setContent(args.content);
                          })
                          .catch((error) => {
                            console.error("Error processing images:", error);
                          });
                      }
                    } // if args.content ends
                  }, // paste_preprocess ends
                  // -------------------- Image paste ends here -----------
                }}
                value={mailDescription}
                onEditorChange={(newContent, editor) => {
                  setMailDescription(newContent);
                }}
              /> */}

              <JoditEditor
                ref={editor}
                value={mailDescription}
                config={editorConfig}
                onBlur={(newContent) => {
                  // console.log("New content in on blur----->", newContent);
                  setMailDescription(newContent);
                }}
                onChange={(newContent, event) => {
                  // setMailDescription(newContent);
                }}
              />
            </div>
            {/* ------- editor section end ------- */}

            {/* ========= file upload start ========== */}
            <div className="form-group mb-4">
              {isUploading ? (
                <div className="d-flex align-items-center gap-1 mb-1">
                  <p className="fw-bold">Please wait while uploading</p>{" "}
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                    return (
                      <div
                        key={index}
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        style={{ height: "6px", width: "6px" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <label htmlFor="Attachments" className="text-nowrap me-lg-3">
                  {t("Attachments")}
                </label>
              )}
              <input
                type="file"
                className="form-control"
                id="uploadedMailTemplateFile"
                multiple
                onChange={uploadHandler}
              />
              {uploadedFiles.length > 0 && (
                <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                  {uploadedFiles.map((file, index) => {
                    return (
                      <li
                        className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                        key={index}
                      >
                        <Link to="#" onClick={() => downloadFileHandler(file)}>
                          <span className="d-block material-symbols-outlined">
                            download
                          </span>
                        </Link>
                        <p className="fw-medium">{file.name}</p>
                        <Link to="#" onClick={() => closeFileHandler(index)}>
                          <span className="d-block material-symbols-outlined">
                            close
                          </span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            {/* ========= file upload end ===========*/}

            {/* <!-- ========== Start link tracker Section ========== --> */}
            <div className="form-group">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <label htmlFor="" className="text-nowrap me-lg-3">
                    {t("Link Tracker")}{" "}
                    <span className="text-primary">
                      ** {t("1. use exact link and 2. use http or https")}
                    </span>
                  </label>
                </div>
              </div>
            </div>

            {/* <!-- ========== Start list of link tracker Section ========== --> */}
            {linkTrackerBlock.map((trackerBlock, index) => {
              return (
                <div
                  className="form-group p-3 bg-gray-500 mb-3 action_link_block_bg"
                  key={index}
                >
                  <div className="row">
                    {/* ------ action link name start ------ */}
                    <div className="col-lg-6 mb-1">
                      <label>{t("Add url to track")}</label>
                      <input
                        type="text"
                        name="link"
                        className="form-control bg-white"
                        placeholder="https://facebook.com"
                        value={trackerBlock.link}
                        onChange={(e) => {
                          changeLinkTrackerValue(index, "link", e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            trackerLinkWarning: false,
                          }));
                        }}
                      />
                      {/* link name warning */}
                      {validation.trackerLinkWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter a link!")}</span>
                          </p>
                        </div>
                      )}
                    </div>
                    {/* ------ action link name end ------ */}

                    {/* ------ action link external start ------ */}
                    <div className="col-lg-5 mb-1">
                      <label>{t("Label to display if link clicked")}</label>
                      <input
                        type="text"
                        name="label"
                        className="form-control bg-white"
                        placeholder={t("visited lobees")}
                        value={trackerBlock.label}
                        onChange={(e) => {
                          changeLinkTrackerValue(
                            index,
                            "label",
                            e.target.value
                          );
                          setValidation((prevState) => ({
                            ...prevState,
                            trackerLabelWarning: false,
                          }));
                        }}
                      />
                      {validation.trackerLabelWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter a label!")}</span>
                          </p>
                        </div>
                      )}
                    </div>
                    {/* ------ action link external end ------ */}

                    <div className="col-lg-1 mb-1 pt-5">
                      <Link
                        to="#"
                        className="text-danger mt-2"
                        onClick={() => {
                          onDeleteLinkTrackerBlock(index);
                        }}
                      >
                        <span className="material-symbols-outlined">close</span>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* <!-- ========== End list of link tracker Section ========== --> */}

            {/* <!-- ========== Start add new link tracker button ========== --> */}
            <div className="form-group mb-5">
              <div className="row">
                <div className="col-3">
                  <Link
                    onClick={onAddLinkTrackerBlock}
                    to="#"
                    className="d-flex align-items-center gap-1 text-primary fw-medium"
                  >
                    <span className="d-block material-symbols-outlined">
                      add
                    </span>
                    <span>{t("Add Link Tracker")}</span>
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- ========== End add new link tracker button ========== --> */}

            {/* <!-- ========== End link tracker Section ========== --> */}
          </div>
          <div className="action d-flex align-items-center gap-3">
            <button
              onClick={saveMailTemplateHandler}
              type="button"
              className="btn btn-primary"
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              disabled={isSaving ? true : false}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>

      <SuccessTemplateModal
        afterSaveTeamplateModalClose={afterModalClose}
        moduleName={moduleName}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default MailTemplateForm;
