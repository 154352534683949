/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const SaveDirectAccessModal = () => {
  const location = useLocation();

  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation

  const [accessTitle, setaccessTitle] = useState("");
  const [accessDetails, setaccessDetails] = useState("");
  const [accessModule, setaccessModule] = useState("");
  const [accessUrl, setaccessUrl] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    urlWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (accessTitle === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (accessUrl === "") {
      setValidation((prevState) => ({ ...prevState, urlWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for saving access
  const saveAccessHandler = async () => {
    if (validationHandler()) {
      seterrorMessage("");
      setIsSaving(true);
      try {
        let accessData = {
          moduleslug: accessModule,
          title: accessTitle,
          description: accessDetails,
          accessurl: accessUrl,
        };

        // console.log(accessData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        requestUrl += url.API_ADD_DIRECT_ACCESS + `?token=${token}`;

        response = await postData(requestUrl, accessData);

        console.log(response);

        setIsSaving(false);

        if (response.status) {
          resetHandler();

          // close the modal

          let currentModal = document.querySelector("#saveDirectAccess");
          let modal = bootstrap.Modal.getInstance(currentModal);
          modal.hide();
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      seterrorMessage(t("Please fill up required fields"));
    }
  };

  const resetHandler = () => {
    seterrorMessage("");
    setaccessTitle("");
    setaccessDetails("");
    setaccessModule(location.pathname.slice(1).replace(/\//g, "_"));
    setaccessUrl(location.pathname);

    setValidation({
      nameWarning: false,
      urlWarning: false,
    });
  };

  useEffect(() => {
    setaccessModule(location.pathname.slice(1).replace(/\//g, "_"));
    setaccessUrl(location.pathname);
  }, []);

  return (
    <div
      className="modal fade"
      id="saveDirectAccess"
      tabIndex="-1"
      aria-labelledby="saveDirectAccess"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="saveDirectAccess">
              {t("Bookmark to Direct Access")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- user select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="urlname"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Add Title")} *
                </label>
                <input
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter Access Name")}
                  value={accessTitle}
                  onChange={(e) => {
                    setaccessTitle(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      nameWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
                {validation.nameWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter title")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ----- user select section end ----- */}

              <div className="form-group mb-4">
                <label
                  htmlFor="slugID"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Details")}
                </label>
                <textarea
                  name="details"
                  id="details"
                  cols="30"
                  rows="6"
                  className="form-control border-0 shadow-none"
                  placeholder={t("Details")}
                  value={accessDetails}
                  onChange={(e) => setaccessDetails(e.target.value)}
                ></textarea>
              </div>

              {/* ----- url section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="role"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Bookmark Url")} *
                </label>

                {/* <input
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter Access Url")}
                  value={accessUrl}
                  onChange={(e) => {
                    setaccessUrl(e.target.value);
                    seterrorMessage("");
                    setValidation((prevState) => ({
                      ...prevState,
                      urlWarning: false,
                    }));
                  }}
                /> */}

                <p className="form-control fs-sm shadow-none">{accessUrl}</p>

                {/* {validation.urlWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter bookmark url")}!</span>
                    </p>
                  </div>
                )} */}
              </div>
              {/* ----- url section end ----- */}

              {/* ----- button section start ----- */}
              <div className="action d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetHandler}
                >
                  {t("Cancel")}
                </Link>

                {errorMessage === "" ? null : (
                  <div className="error-message">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{errorMessage}!</span>
                    </p>
                  </div>
                )}

                <button
                  type="button"
                  className="btn btn-primary ms-auto"
                  onClick={saveAccessHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* ----- button section end ----- */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveDirectAccessModal;
