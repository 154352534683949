import React from "react";

import {formatNumber} from "helper/Common/CommonHelper";

const InvoiceCard = ({
  totalInvoiceAmount,
  taskDataLoading,
}) => {

  if (taskDataLoading) {
    return (
        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <div className="project h-100 bg-gray-200 p-3 p-lg-4 d-flex gap-3 rounded-10 shadow-sm">
            <div className="info flex-fill">
              <div className="glow-placeholder" style={{ height: "2rem", width: "50%" }}></div>
              <div className="glow-placeholder" style={{ height: "1.5rem", width: "30%", marginTop: "1rem" }}></div>
              <div className="glow-placeholder" style={{ height: "1rem", width: "70%", marginTop: "1rem" }}></div>
            </div>
            <div
              className="icon flex-shrink-0 d-flex align-items-center justify-content-center rounded-18"
              style={{
                width: "3.75rem",
                height: "3.75rem",
                backgroundColor: "rgb(74 217 145 / 20%)",
                color: "#4AD991",
              }}
            >
              <span
                className="d-block material-symbols-outlined icon-fill"
                style={{ fontSize: "2rem" }}
              >
                diversity_3
              </span>
            </div>
          </div>
        </div>
      );
  }

  return (
    <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
      <div className="resources h-100 bg-gray-200 p-3 p-lg-4 d-flex gap-3 rounded-10 shadow-sm">
        <div className="info flex-fill">
          <p className="fw-semibold mb-3">Total Invoice Sent</p>
          <div className="d-flex align-items-end gap-1 mb-1">
            <h2 className="fw-bold lh-1 mb-0">{formatNumber(totalInvoiceAmount)} &euro;</h2>
          </div>
          <p className="fs-xs d-flex align-items-center">
            <span className="d-block material-symbols-outlined text-success icon-md me-1">
              trending_up
            </span>
            <span className="d-block text-success">6%</span>
            <span className="d-block text-gray">
              &nbsp;Increase from Last Month
            </span>
          </p>
        </div>
        <div
          className="icon flex-shrink-0 d-flex align-items-center justify-content-center rounded-18"
          style={{
            width: "3.75rem",
            height: "3.75rem",
            backgroundColor: "rgb(74 217 145 / 20%)",
            color: "#4AD991",
          }}
        >
          <span
            className="d-block material-symbols-outlined icon-fill"
            style={{ fontSize: "2rem" }}
          >
            diversity_3
          </span>
        </div>
      </div>
    </div>
  );
};

export default InvoiceCard;
