/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";
import { useTranslation } from "react-i18next";

const SaveModuleRolePopup = ({
  moduleList,
  afterPopupCLose,
  moduleRoleId,
  setModuleRoleId,
}) => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");

  const [moduleValue, setmoduleValue] = useState(null);
  const [selectedModule, setselectedModule] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
    modulewarning: false,
  });

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (name === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (slug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    if (selectedModule === null) {
      setValidation((prevState) => ({ ...prevState, modulewarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //select module
  const moduleSectionHandler = (val) => {
    if (val) {
      setValidation((prevState) => ({
        ...prevState,
        modulewarning: false,
      }));
      setmoduleValue(val);
      setselectedModule(val.value);

      if (name !== "") {
        setSlug(`${val.slug}_${name === "" ? "" : name.toUpperCase()}`);
      }
    } else {
      setmoduleValue(null);
      setselectedModule(null);
    }
  };

  //get details
  const getDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_MODULE_ROLE_DETAILS +
        `/${moduleRoleId}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setName(response.data.name);
        setSlug(response.data.slug);
        moduleSectionHandler(response.data.moduledata);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create new tag
  const saveHandler = async () => {
    if (validate()) {
      setIsSaving(true);
      try {
        let sendingData = {
          name,
          slug,
          module: selectedModule,
        };

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (moduleRoleId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_MODULE_ROLE +
            `/${moduleRoleId}` +
            `?token=${token}`;

          response = await putData(requestUrl, sendingData);
        } else {
          requestUrl =
            requestUrl + url.API_CREATE_MODULE_ROLE + `?token=${token}`;

          response = await postData(requestUrl, sendingData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          // close the modal of addLabel
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#saveModuleRole")
          ).hide();
          resetHandler();
          afterPopupCLose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  /* reset */
  const resetHandler = () => {
    setName("");
    setSlug("");
    setmoduleValue(null);
    setselectedModule(null);
    setModuleRoleId(null);
  };

  useEffect(() => {
    if (moduleRoleId) {
      getDetails();
    }
  }, [moduleRoleId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="saveModuleRole"
      aria-labelledby="saveModuleRole"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          {t("Save Module Role")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Title")}
              </label>
              <input
                type="text"
                id="labelName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    nameWarning: false,
                  }));
                }}
              />
              {/* level warning */}
              {validation.nameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter label")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* slug */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Slug")}
              </label>
              <input
                type="text"
                id="slug"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    slugWarning: false,
                  }));
                }}
              />
              {/* slug warning */}
              {validation.slugWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter slug")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* module */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Select Module")}
              </label>
              <Select
                placeholder={t("Select Module")}
                isClearable
                options={moduleList}
                value={moduleValue}
                onChange={(val) => moduleSectionHandler(val)}
              />
              {/* slug warning */}
              {validation.modulewarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select module")}!</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetHandler}
            >
              {t("Close")}
            </Link>
            <button
              onClick={saveHandler}
              type="button"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveModuleRolePopup;
