/* eslint-disable */
import React, { useState } from "react";
import Select from "react-select";

const DashboardReportIssueOldModal = () => {
  // select data --------------------------
  const options = [
    { label: "Option 1", value: "Option 1" },
    { label: "Option 2", value: "Option 2" },
  ];

  // useState to select data ------------------
  const [selectOptions, setSelectOptions] = useState(null);

  return (
    <div
      className="modal fade"
      id="reportDashboardIssueModal"
      tabIndex="-1"
      aria-labelledby="reportIssueModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="reportIssueModalLabel">
              Report an Issue
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <form action="" className="mt-4">
              <div className="form-group mb-4">
                <label
                  htmlFor="course"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Course
                </label>
                <Select
                  placeholder="Select options"
                  options={options}
                  value={selectOptions}
                  onChange={(val) => {
                    setSelectOptions(val);
                  }}
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="comments"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Comments
                </label>
                <textarea
                  name="comments"
                  id="comments"
                  cols="30"
                  rows="5"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter your comments"
                ></textarea>
              </div>
              <div className="action">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardReportIssueOldModal;
